import { Box, Button, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { CardHeader, CardBody, CardUI } from 'Components/Common/Card';
import React from 'react';
import FreeMembershipCard from './FreeMembershipCard';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { t } from 'i18next';
import { getLanguage } from 'language/i18n';
import { useRecoilState } from 'recoil';
import { selectedProductUIDState } from 'Config/recoil';
import { useNavigate } from 'react-router-dom';
import UseCreatePayment from 'Hook/UseCreatePayment';
import useLoadPaypalUI from 'Hook/useLoadPaypalUI';
import { checkedToken } from 'Hook/CheckedToken';
import dayjs from 'dayjs';

const VideogelMembership = ({ setPaymentInfomation }) => {
  const [selectedProductUID, setSelectedProductUID] = useRecoilState(
    selectedProductUIDState,
  );
  const loadPaypalUI = useLoadPaypalUI();
  const token = localStorage.getItem('token');
  const createPayment = UseCreatePayment();

  const HandleSelect = async (e) => {
    setSelectedProductUID('video-1');
    setPaymentInfomation({
      paymentMap: 'VIDEO_PAYMENT',
      planUid: '90',
      price: 10000,
      membershipName: '비디오젤',
      serviceToken: 10,
    });

    // 페이팔 렌더링
    // const user = JSON.parse(localStorage.getItem('User'));
    // const valid = await checkedToken(token);

    // loadPaypalUI({
    //   uid: '90',
    //   serviceToken: 10,
    //   moidNum: dayjs(new Date()).unix(),
    //   membershipName: '비디오젤',
    //   price: 8,
    //   email: user.email,
    //   userName: user.userName,
    //   planUid: 90,
    //   callback: createPayment,
    //   valid,
    // });
  };

  return (
    <SimpleGrid
      w={'100%'}
      justify="center"
      maxW="800px"
      m="0 auto"
      templateColumns={{
        md: 'repeat(2, 1fr)',
      }}
      spacing="40px"
    >
      <FreeMembershipCard />
      <CardUI selected={selectedProductUID === 'video-1'}>
        <CardHeader
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Heading
            as="h3"
            size="sm"
            pb="15px"
            borderBottom={'1px solid #ededed'}
          >
            비디오젤 가입시
          </Heading>
          <Text fontSize={'2xl'} fontWeight="600" pt="15px">
            {getLanguage() === 'ko' ? '10,000' : '8'}
            {t('membership.currency')}
          </Text>
        </CardHeader>
        <CardBody
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box lineHeight={'30px'}>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              비디오젤만 사용 가능한 멤버십
            </Flex>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              비디오젤 토큰 10개(비디오 생성 10개)
            </Flex>
          </Box>
          <Button
            w="100%"
            colorScheme="purple"
            name="1 10000"
            onClick={HandleSelect}
          >
            {t('membership.select_button')}
          </Button>
        </CardBody>
      </CardUI>
    </SimpleGrid>
  );
};

export default VideogelMembership;
