import axios from 'axios';
import { SERVER_URL } from 'Config/server';

export function getMyProfile() {
  return axios.get(`${SERVER_URL}/user/profile`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
}

export function postEmail({ name, email }) {
  return axios.post(
    `${SERVER_URL}/user/basic/email?email=${email}&name=${name}`,
    {},
    {
      headers: { Authentication: localStorage.getItem('token') },
    },
  );
}

export function postInquiry({ body }) {
  return axios.post(`${SERVER_URL}/user/basic/inquiry`, body, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
}

// 라이팅젤 결제 플랜 가져오기
export function getPayWritingel() {
  return axios.get(`${SERVER_URL}/user/pay/writingel/new`);
}

// 쿠폰 사용
export function postPayCoupon({ coupon }) {
  return axios.post(
    `${SERVER_URL}/user/pay/coupon?coupon_uid=${coupon}`,
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    },
  );
}

// 최근 결제 기록 가져오기
export function getUserPay() {
  return axios.get(`${SERVER_URL}/user/pay`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
}

export function postSignUp({ token }) {
  return axios.post(`${SERVER_URL}/user/signup`, {
    provider: 'firebase',
    token: token,
  });
}

export function postLogIn({ token }) {
  return axios.post(`${SERVER_URL}/user/login`, {
    provider: 'firebase',
    token: token,
  });
}

export function postKakaoLogIn({ access }) {
  return axios.post(`${SERVER_URL}/user/login`, {
    provider: 'kakao',
    token: access,
  });
}

export function postKakaoSignUp({ access }) {
  return axios.post(`${SERVER_URL}/user/signup`, {
    provider: 'kakao',
    token: access,
  });
}

export function deleteLongtermPay() {
  return axios.delete(`${SERVER_URL}/user/pay/long-term-pay`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
}

export function postMulitiplePay(body, navigate) {
  return axios
    .post(`${SERVER_URL}/user/pay/writingel/multiple`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      navigate('/paydone');
    })
    .catch((error) => {
      const errorStatus = error.response.status;
      const errorResMessage = error.response.data.message;
      navigate('/failpay', {
        state: {
          errorStatus: errorStatus,
          errorResMessage: errorResMessage,
        },
      });
    });
}
