import { Box, Button, Flex, Input, useToast } from '@chakra-ui/react';
import { getAuth, sendPasswordResetEmail } from '@firebase/auth';
import Loading from 'Components/Common/Loading';
import { app } from 'Config/Firebase';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';

const ResetPassword = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isSended, setIsSended] = useState(false);

  const sendResetEmail = useCallback(
    (e) => {
      e.preventDefault();
      const auth = getAuth(app);
      sendPasswordResetEmail(auth, email)
        .then((e) => {
          setIsSended(true);
        })
        .catch((error) => {
          console.error(error);
          const isNoUser = error
            .toString()
            .includes(
              'FirebaseError: Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found).',
            );

          if (isNoUser) {
            toast({
              position: 'top-right',
              title: t('error.not_found_user_title'),
              description: t('error.not_found_user_description'),
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
            return;
          }

          toast({
            position: 'top-right',
            title: 'Error',
            description: 'An error occurred during email reset.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
    },
    [email, toast],
  );

  return (
    <>
      {loading && <Loading />}

      <Box
        maxW="480px"
        m="0 auto"
        p={{ base: '40px 20px', md: '40px' }}
        bg="#fff"
        border="1px solid #dedede"
        borderRadius={'10px'}
      >
        {isSended ? (
          <p>{t('reset_password.sended_reset_email')}</p>
        ) : (
          <form onSubmit={sendResetEmail}>
            <h3
              style={{
                fontSize: '20px',
                marginBottom: '10px',
              }}
            >
              {t('reset_password.title')}
            </h3>
            <p>{t('reset_password.description')}</p>
            <Flex alignItems={'center'} marginTop={'30px'}>
              <label
                style={{
                  wordBreak: 'keep-all',
                  marginRight: '10px',
                }}
              >
                {t('reset_password.label')}
              </label>
              <Input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </Flex>
            <Flex w={'100%'} justifyContent={'center'} marginTop={'20px'}>
              <Button w={'100%'} type="submit">
                {t('reset_password.reset')}
              </Button>
            </Flex>
          </form>
        )}
      </Box>
    </>
  );
};

export default ResetPassword;
