import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'Routes/App';
import './Styles/Common.scss';
import { CookiesProvider } from 'react-cookie';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

// export const system = createSystem(defaultConfig, {
//   theme: {
//     tokens: {
//       fonts: {
//         heading: { value: `'Figtree', sans-serif` },
//         body: { value: `'Figtree', sans-serif` },
//       },
//     },
//   },
// });

const customTheme = extendTheme({
  breakpoints: {
    sm: '480px',
    md: '768px',
    lg: '960px',
    xl: '1024px',
    '2xl': '1200px',
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "'Spoqa Han Sans Neo', 'sans-serif'",
      },
    },
  },
});
AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <ChakraProvider theme={customTheme} resetCSS={true}>
      <CookiesProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CookiesProvider>
    </ChakraProvider>
  </RecoilRoot>,
);
