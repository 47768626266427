const ko = {
  ko: {
    translation: {
      common: {
        login: '로그인',
        logout: '로그아웃',
        signup: '회원가입',
        need_login: '로그인이 필요합니다!',
        login_check_modal_heading: '라이팅젤 회원이신가요?',
        login_check_modal_description1: '로그인을 먼저 해주세요!',
        login_check_modal_description2:
          '회원이 아니라면 회원가입 버튼을 눌러주세요!',
        title_input_placeholder: '제목을 적어주세요.',
        rewrite_tooltip: '누르면 새로운 이야기가 나타납니다.',
        download_tooltip: '아래 결과를 저장할 수 있습니다.',
        drawing_from_result: '해당 결과로 그림 그리기',
        next_chapter_creation: '만들기',
        close: '닫기',
        months: '개월',
      },
      header: {
        about_us: '소개',
        service_drawer_title: 'AI 서비스',
        service_drawer_ai_writing: 'AI 글쓰기 서비스',
        service_drawer_ai_drawing: 'AI 그리기 서비스',
        service_drawer_ai_chatting: 'AI 채팅 서비스',
        service_drawer_bookstore: '팅젤문고 시리즈',
        membership: '멤버십',
        newsletter: '뉴스레터',
        support_drawer_title: '고객지원',
        support_drawer_notice: '공지사항',
        support_drawer_faq: '자주 묻는 질문',
        support_drawer_contact: '문의하기',
        writingel_home: '라이팅젤 홈',
        main_home: '메인화면',
      },
      home: {
        contents_writing: 'AI 글쓰기',
        contents_drawing: 'AI 그리기',
        contents_chatting: 'AI 채팅',
        contents_versus: 'AI 대결',
        section1_title1: '글쓰기가 즐거워지는 순간',
        section1_title2: '막막했던 글쓰기가 술술',
        section1_description1: '라이팅젤에서 AI와 함께 글을 써보세요!',
        section1_description2:
          '드로잉젤을 통해 텍스트를 그림으로 만들 수 도 있답니다.',
        section1_go_to_writingel: '인공지능 글쓰기 무료체험 해보기',
        section1_notice_second: '동화책 출간하기 서비스가 새로 오픈되었습니다.',
        section1_notice_third_chunk1: '채팅젤이 새롭게',
        section1_notice_third_chunk2: '되었어요!',
        section1_notice_third_go_to_chattingel: '채팅젤로 이동하기',
        section1_notice_third_chunk3: '버튼을 눌러 채팅젤을 체험해보세요!',
        section2_feature1_title: ' 100% 인공지능 창작, 표절 & 저작권 걱정 NO',
        section2_feature1_description:
          '모든 결과물은 기존 창작물에서 가져오는 것이 아니라, 인공지능이 완전히 새롭게 창작하는 것이므로 표절 문제에서 자유롭습니다. 저작권 역시 해당 사용자(멤버십)에게 귀속됩니다.',
        section2_feature2_title: '계속 새로운 서비스 출시, 무제한 사용',
        section2_feature2_description:
          ' 앞으로 새로운 서비스를 새롭게 개발/출시할 예정입니다. 멤버십에 가입하시면, 사용 기간 내 출시되는 서비스는 멤버십 가입시 모두 무제한으로 사용하실 수 있습니다.',
        section2_feature3_title: '교육 & 커뮤니티 활동',
        section2_feature3_description:
          '인공지능을 활용한 글쓰기, 전자책, 개인 콘텐츠 만들기 등 관련 교육을 받을 수 있고, 그 외 온·오프라인에서 함께 창작활동을 할 수 있습니다.',
        section3_title_heading1: '빈 종이의 막막함을 해결해드려요.',
        section3_title_heading2: '짧은 시간 안에 글을 완성할 수 있습니다.',
        section3_sequence1_title: '쓰려는 글의 장르, 종류를 선택합니다.',
        section3_sequence1_description:
          '일기, 블로그, 소설, 시, 동화, 이메일, 홍보문구, 뉴스레터 등 다양한 장르의 글을 선택할 수 있습니다.',
        section3_sequence2_title:
          '간단한 키워드를 입력하고 글쓰기 버튼을 클릭합니다.',
        section3_sequence2_description:
          '선택한 장르의 글에 필요한 요소(주제, 키워드, 문장 등)를 입력하고 버튼을 클릭합니다.',
        section3_sequence3_title: '자동 완성된 결과를 살펴봅니다.',
        section3_sequence3_description:
          '입력한 내용에 맞게 자동 완성된 결과를 확인합니다.',
        section3_sequence4_title: '완성된 글을 편집해서 사용해 보세요',
        section3_sequence4_description:
          '이어 쓰거나 드로잉젤로 그림을 그릴 수도 있습니다.',
        section3_sequence_detail: '사용방법 자세히 보기',
        section4_title: '글을 그림으로 만들어보세요.',
        section4_description:
          '드로잉젤을 사용해 글과 관련된 그림을 받아볼 수 있습니다.',
        section4_p1:
          '드로잉젤에서 텍스트를 넣고 화풍과 크기, 이미지 갯수를 선택하면 AI가 옵션에 맞게 그림을 그려줍니다.',
        section4_p2: '이미지를 저장해 마음껏 활용해보세요!',
        section4_go_to_drawingel: '드로잉젤 무료체험 해보기',
        section4_annotation:
          '해당 그림들은 라이팅젤 결과로 드로잉젤에서 만든 그림입니다.',
        section5_title: '채팅젤을 무료로 체험해보세요!',
        section5_description:
          '채팅젤에서 질문하고 AI에게 답변을 받아보실 수 있습니다.',
        section5_go_to_chattingel: '채팅젤 무료체험 해보기',
        section5_example_system: '채팅을 시작합니다.',
        section5_example_bot1: '채팅젤 속 챗봇이 질문에 대한 답변을 해드려요.',
        section5_example_bot2:
          'AI가 만든 결과를 라이팅젤, 드로잉젤에도 사용할 수 있습니다.',
        section5_example_user: '무료체험 버튼을 눌러 채팅젤을 이용해보세요!',
        section5_sequence_fisrt:
          '채팅젤 메뉴에 들어가서 질문을 입력해주세요. 음성으로도 입력이 가능합니다!',
        section5_sequence_second: '전송 버튼을 눌러 AI에게 질문을 보내주세요.',
        section5_sequence_thrid:
          'AI가 생성한 내용을 가지고 라이팅젤과 드로잉젤에서 활용해보세요!',
        section6_go_to_about_us: '라이팅젤 소개',
        section6_go_to_membership: '멤버십 가입',
        section7_title: '학급 별로 관리하세요',
        section7_description:
          '단체로 멤버십을 결제하면 학급을 관리할 수 있는 대시보드 기능을 제공해 드립니다.',
        section7_p1:
          '학생정보, 학생통계, 학생별 사용 기록을 확인할 수 있습니다.',
        section7_p2: '단체 결제는 아래 메일로 문의해주시기 바랍니다.',
        section7_annotation0:
          '* 사용기록은 그룹에 등록된 시점부터 확인할 수 있습니다.',
        section7_annotation1:
          '* 기존 단체 결제 이용자분들께서는 필요한 경우 문의 부탁드립니다.',
      },
      footer: {
        company_name: '(주)앱플랫폼',
        company_ceo: '류석구',
        company_ecommerce_license_number:
          '통신판매신고번호 : 제2021-서울서초-4241호',
        company_address: '주소 :서울시 강남구 언주로536 태승BD 5층',
        contact_time: '운영시간:평일 09:30~17:30',
        terms_of_service: '이용약관',
        privacy_policy: '개인정보 처리방침',
      },
      about_us: {
        section1_hightlight_heading: '글쓰기가 즐거워지는 순간',
        section1_orange_heading: '막막한 글쓰기가 뚝딱',
        section1_puple_heading: '정답은 라이팅젤',
        section1_heading1_chunk1: '빈 종이의 막막함을 해결해드려요.',
        section1_heading1_chunk2: '자연스러운 AI 인공지능 글쓰기',
        section1_heading1_chunk3: '당신의 모든 이야기에',
        section1_heading1_chunk4: '무한한 영감을 드립니다.',
        section1_writingel_name: '라이팅(writing; lighting;)젤',
        section1_writingel_description_chunk1:
          '창작자가 자신만의 콘텐츠를 만드는 모든 과정이',
        section1_writingel_description_chunk2: '더 가벼워지고 무한한 영감으로',
        section1_writingel_description_chunk3:
          '창작 활동이 더 밝아졌으면 하는 바람으로 만든',
        section1_writingel_description_chunk4: ' 인공지능 기반의',
        section1_writingel_description_chunk5: '글쓰기 창작서비스입니다.',
        section1_writingel_description_chunk6:
          '인공지능이 인간의 창작활동을 대체할 지도 모른다는 우려를 뒤집어 오히려 인간의 창작활동을 돕는 도구로써 활용할 수 있는 방법을 제시합니다.',
        section1_go_to_writingel: '바로 가입하기',
        section1_heading2_chunk1: '클릭만 하면',
        section1_heading2_chunk2: '손쉽게 완성되는',
        section1_heading2_chunk3: '글쓰기 시작하세요.',
        section1_heading3_chunk1: '원하는 글쓰기 유형을 고르고,',
        section1_heading3_chunk2: '주제와 키워드를 입력하기만 하면 완성!',
        //
        section2_heading1_chunk1: '쉽고 빠르게',
        section2_heading1_chunk2: '당신의',
        section2_heading1_chunk3: '글쓰기를 도와드려요.',
        section2_heading2_chunk1: '혼자하는 글쓰기 힘드시죠?',
        section2_heading2_chunk2: '빈 종이 앞에 끙끙대고 계신가요?',
        section2_heading2_chunk3: '라이팅젤로 무한한 영감을 받으세요.',
        section2_sequence1_title_chunk1: '주제와 키워드를',
        section2_sequence1_title_chunk2: '입력하세요',
        section2_sequence1_description_chunk1: '라이팅젤이 글쓰기의 시작과',
        section2_sequence1_description_chunk2: '이어쓰기를 돕습니다.',
        section2_sequence1_keyword1: '주제',
        section2_sequence1_keyword2: '제목',
        section2_sequence1_keyword3: '개요',
        section2_sequence1_keyword4: '도입부',
        section2_sequence2_title_chunk1: '저작권 걱정없는',
        section2_sequence2_title_chunk2: '순수 창작물',
        section2_sequence2_description_chunk1:
          '죽을 때까지 새로운 순수 창작물 제작',
        section2_sequence2_desciprition_chunk2: '100번 쓰면, 100번 다 다르게!!',
        section2_sequence2_example_title: 'MBTI 연애편지',
        section2_sequence2_copyright:
          '* 결과물의 저작권은 사용자에게 귀속됩니다.',
        section2_sequence3_title_chunk1: '손 안에',
        section2_sequence3_title_chunk2: '글쓰기 요정',
        section2_sequence3_description_chunk1:
          '여러분의 창작에 라이팅젤의 영감을 더해',
        section2_sequence3_description_chunk2:
          '당신의 창작물에 날개를 달아드립니다.',
        section3_heading1_chunk1: '글쓰기는',
        section3_heading1_chunk2: '라이팅젤',
        section3_heading1_chunk3: '과 함께라면',
        section3_heading1_chunk4: '문제 없어요 :)',
        section3_heading2_chunk1: '인공지능 자연어 처리 모델',
        section3_heading2_chunk2: "gpt-4o 활용한 창작도우미 '라이팅젤'",
        section3_number_one: '1위',
        section3_bigdata_users_chunk1: '빅데이터',
        section3_bigdata_users_chunk2: '사용자 수',
        section3_awareness_chunk1: '인공지능',
        section3_awareness_chunk2: '인지도',
        section3_parameter_count: '1750억개',
        section3_parameter: '매개변수 보유',
        section3_heading3_chunk1: '라이팅젤의',
        section3_heading3_chunk2: '글쓰기 솔루션',
        section3_heading4_chunk1: '라이팅젤과 함께 글을 써봐요!',
        section3_heading4_chunk2: '창의적 글쓰기에 필요한 걸 드릴게요.',
        section3_novel_chunk1: '소설',
        section3_novel_chunk2: '패키지',
        section3_blog_chunk1: '블로그',
        sectoin3_blog_chunk2: '글쓰기',
        section3_fairy_tail_chunk1: '동화',
        section3_fairy_tail_chunk2: '쓰기',
        section3_first_sentence_chunk1: '첫문장',
        section3_first_sentence_chunk2: '자판기',
        section3_eng_lyrics_chunk1: '영어 가사',
        section3_eng_lyrics_chunk2: '쓰기',
        section3_business_idea_chunk1: '비즈니스',
        section3_business_idea_chunk2: '아이디어',
        section3_discussion_chunk1: '찬반',
        section3_discussion_chunk2: '논거',
        section3_mbti_loveletter_chunk1: 'MBTI',
        section3_mbti_loveletter_chunk2: '연애편지',
        section3_daily_question_chunk1: '일상기록',
        section3_daily_question_chunk2: '질문 카드뽑기',
        section3_story_material_chunk1: '이야기',
        section3_story_material_chunk2: '재료 찾기',
        section3_admission_essay_chunk1: '대입 자소서',
        section3_admission_essay_chunk2: '자동 완성',
        section3_novel_relay_chunk1: '1:1',
        section3_novel_replay_chunk2: '소설 릴레이',
        section3_heading5_chunk1: '국내 최초!!',
        section3_heading5_chunk2: '놓치치 마세요!',
        section3_heading6_chunk1: '기업이 아닌 개인 사용자에게',
        section3_heading6_chunk2: '자연어 처리 인공지능 서비스를',
        section3_heading6_chunk3: '직접 경험할 수 있는 기회를 드립니다.',
        section3_heading7_chunk1: '단순한 동작만으로',
        section3_heading7_chunk2: '자신만의 콘텐츠를',
        section3_heading7_chunk3: '시작하고 완성해보세요',
        section3_go_to_writingel: '다양한 글쓰기 무료체험 해보기 >',
      },
      membership: {
        title: '멤버쉽',
        category_all: '통합',
        category_writingel: '라이팅젤',
        category_drawingel: '드로잉젤',
        category_chattingel: '채팅젤',
        product_title_chunk: '개월 이용권',
        regular_payment: '정기결제',
        currency: '원',
        all_benefit1: '모든 서비스 통합 멤버십',
        all_benefit2: '개월 기간 내 서비스 무제한 이용',
        all_benefit3: '사용기간 만료시 자동으로 정기결제',
        all_benefit4: '해지시 만료기간까지 사용가능',
        select_button: '선택하기',
        writingel_benefit1: '모든 서비스 통합 멤버십',
        writingel_benefit2: '개월 기간 내 서비스 무제한 이용',
        drawingel_benefit1: '드로잉젤만 사용 가능한 멤버십',
        drawingel_benefit2: '드로잉젤 이미지 80장(장당 125원)',
        chattingel_benefit1: '채팅젤만 사용 가능한 멤버십',
        chattingel_benefit2: '채팅젤 토큰 10만개 충전',
        kakao_pay: '카카오페이',
        payment_card: '카드 결제',
        paypal: '페이팔',
        membership: '멤버십',
        payment: '결제',
        payment_button: '결제하기',
        payment_card_notice1_chunk1: '* 메일주소와 번호를 ',
        payment_card_notice1_chunk2: '정확하게',
        payment_card_notice1_chunk3: ' 적어주세요.',
        payment_card_notice2_chunk1: '적어주신 메일과 번호로',
        payment_card_notice2_chunk2: ' 결제가 진행됩니다.',
        payment_card_notice3_chunk1: '결제창이 열린 후',
        payment_card_notice3_chunk2:
          ', 카드 앞면에 사용자 실명이 기재된 기명 법인카드의 경우 생년월일을, 카드 앞면에 회사 명칭이 기재된 무기명 법인 카드일 경우 사업자 번호를 입력해주세요.',
        payment_card_user_name: '이름',
        payment_card_user_name_placeholder: '이름을 입력해주세요',
        payment_card_email_placeholder: '메일을 입력해주세요',
        payment_card_phone: '휴대폰/전화번호',
        payment_card_phone_placeholder: '숫자만 입력해주세요',
        payment_card_phone_required: '휴대폰 번호를 입력해주세요!',
        payment_card_phone_min_length: '길이 부족',
        payment_card_phone_max_length: '길이 초과',
        pay_info_heading1: '멤버십 안내',
        pay_info_description1:
          '멤버십 가입을 위한 결제가 완료되면, 곧바로 서비스를 이용하실 수 있습니다.',
        pay_info_description2:
          '멤버십 이용 기간은 상품에 해당되는 월(1개월 뒤, 3개월 뒤, 6개월 뒤)에 동일한 날짜까지 입니다.',
        pay_info_description3:
          '기간 완료 전에 멤버십 이용을 취소하셔도, 해당 기간까지 서비스를 이용할 수 있습니다.',
        pay_info_heading2: '환불 안내',
        pay_info_description4:
          '결제일로부터 7일이 지나지 않았고 서비스 이력이 없는 경우, 콘텐츠 이용 취소 및 전액 환불이 가능합니다.',
        pay_info_description5:
          '결제 취소 및 환불은 환불 신청 접수 후 7영업일 이내에 처리합니다.',
        pay_info_description6: '환불 신청 절차는 FAQ에서 확인하실 수 있습니다.',
      },
      notice: {
        title: '공지사항',
        post1_headline: '라이팅젤 웹사이트 및 전체 서비스 리뉴얼',
        post1_created_at: '2022.12.16',
        post1_text1:
          '인공지능 글쓰기 서비스 라이팅젤을 이용해주셔서 감사합니다.',
        post1_text2:
          '더 편리하게 서비스를 이용하실 수 있도록 웹사이트 및 전체 서비스를 개편할 예정입니다. 개편된 웹사이트는 2022년 12월 19일 (월) 15:00부터 이용하실 수 있습니다.',
        post1_text3: '세부 서비스 이용 화면 분할',
        post1_text4:
          '모든 서비스의 화면을 동일하게 분할하여 이용 편의성을 높였습니다.',
        post1_text5:
          '서비스 이용 시 이용 중인 서비스를 확인하실 수 있고, 다른 항목으로 쉽게 이동 가능합니다.',
        post1_text6: '보다 친절한 가이드 제공',
        post1_text7:
          '서비스 이용 시작 시, 변경된 화면 및 구성에 맞게 가이드라인을 제공합니다.',
        post1_text8: '각 화면에서도',
        post1_text9:
          '위에 마우스를 올려놓으시면, 서비스 이용 가이드를 확인하실 수 있습니다.',
        post1_text10: "'이어쓰기' 이용 편의성 향상",
        post1_text11:
          '블로그, 동화, 소설 등의 ‘이어쓰기’ 서비스를 보다 더 편리하게 이용하실 수 있도록 개선했습니다.',
        post1_text12: '인공지능 글쓰기 결과물 향상',
        post1_text13:
          '‘첫문장 자판기’, ‘판타지 세계관’ 등에서는 세부 장르를 선택해서 보다 더 만족스러운 결과물을 받아보실 수 있습니다.',
        post1_text14:
          '‘취업자소서’, ‘대입자소서’ 등에서는 개인 경험을 선택 입력하셔서 보다 더 잘 맞춰진 결과물을 받아보실 수 있습니다.',
        post1_text15: '입력 최대 글자수 및 결과물 최대 글자수 변경',
        post1_text16:
          '서비스의 오용을 최소화하기 위해 일부 서비스에서 사용자가 입력할 수 있는 최대 글자수를 조정했습니다.',
        post1_text17:
          '일부 서비스에서 사용자가 받아보는 결과물의 최대 글자수를 늘렸습니다.',
        post1_text18:
          '라이팅젤은 앞으로도 이용자 여러분이 더 만족스러운 결과를 경험하실 수 있도록 노력하겠습니다. 혹시 이용 중 불편하신 내용이 있으시면, 문의하기 페이지 혹은 support@appplatform.co.kr로 연락해주세요.',
        post1_text19: '감사합니다.',
        post2_headline: '라이팅젤 멤버십 방식 변경',
        post2_created_at: '2022.04.29',
        post2_text1: '안녕하세요. 라이팅젤입니다.',
        post2_text2: '2022년 5월 9일 부로 가격 정책을 변동함을 알려드립니다.',
        post2_text3:
          '정기 결제로 정해진 기간 후 사용권을 갱신했던 기존 방식에서',
        post2_text4:
          '일시 결제로 정해진 기간 후 사용권이 만료되는 방식으로 바꾸고자 합니다.',
        post2_text5: '예:',
        post2_text6:
          '기존) 5월 1일에 3개월 정기 결제시, 8월 1일에 사용권 자동 갱신',
        post2_text7:
          '현재) 5월 1일에 3개월 일시 결제시, 7월 31일에 사용권 만료',
        post2_text8: '앞으로도 저희 서비스를 더 편리하게 사용할 수 있도록',
        post2_text9: '라이팅젤 사용자의 요구를 파악하고 빠르게 대응하겠습니다.',
        post2_text10: '감사합니다.',
      },
      faq: {
        title: '자주 묻는 질문',
        faq_account: '계정',
        faq_account_question1: '계정을 삭제하려면 어떻게 해야 하나요?',
        faq_account_answer1: `
                탈퇴 및 계정 삭제 관련 문의는 support@appplatform.co.kr로
                보내주세요. 계정 삭제 시 멤버십 가입 내역도 삭제됩니다.
                멤버십에 가입하여 서비스를 이용하는 중에 계정 삭제(탈퇴)를
                하면 더 이상 이용이 불가합니다.
                신중한 결정 부탁드립니다.`,
        faq_membership: '멤버십',
        faq_membership_question1: '멤버십 이용료 환불 규정이 궁금해요.',
        faq_membership_answer1: `
                결제일로부터 7일이 지난 경우, 콘텐츠 이용 취소가 불가합니다.결제일로부터 7일이 지나지 않았고 서비스이력이 없는 경우, 콘텐츠 이용 취소 및 전액 환불이 가능합니다. 결제 취소 및 환불은 환불 신청 접수 후 7영업일 이내에 처리합니다.`,
        faq_membership_question2: '멤버십 이용료 환불 절차를 알려주세요',
        faq_membership_answer2: `
                콘텐츠 이용을 취소하고자 하는 이용자(결제일로부터 7일이 지나지
                    않았고 서비스이력이 없는 경우만 가능)는 서비스 취소 신청서를
                    작성하여 support@appplatform.co.kr로 보냅니다. 신청서에는 취소
                    사유, 이용자 연락처(이메일 주소, 휴대폰 번호) 등을 기입합니다.
                    취소 신청서가 접수된 후, 해당 이용자의 서비스 이력을 확인하여
                    취소 가능 여부를 확인합니다. 취소가 가능한 경우, 7영업일
                    이내에 이용대금 결제와 동일한 방법으로 환불이 됩니다.`,
        faq_membership_question3:
          '멤버십을 이용하는 사용자 계정을 공유 혹은 양도할 수 있나요?',
        faq_membership_answer3: `라이팅젤 멤버십 계정은 공유와 양도 모두 불가합니다.`,
        faq_membership_question4:
          '새로운 서비스가 오픈될 때마다 알림을 받을 수 있나요?',
        faq_membership_answer4: `
                가입해주신 계정(이메일 주소)으로 보내드리는 뉴스레터를 통해
                새로운 소식을 알려드릴 예정입니다. 뉴스레터 구독에
                동의해주시면 새로운 소식을 가장 빨리 접하실 수 있습니다.`,
        faq_membership_question5:
          '멤버십 사용자를 위한 서비스 이용 관련 교육을 제공하나요?',
        faq_membership_answer5: `
              서비스 이용 안내 혹은 글쓰기 관련 강의를 월 1회 진행하려고
                  계획하고 있습니다. 관련 소식은 뉴스레터를 통해 보내드리도록
                  하겠습니다.`,
        faq_payment: '결제',
        faq_payment_question1: '결제 영수증은 어디서 확인할 수 있나요?',
        faq_payment_answer1: `
              라이팅젤 웹사이트 우측 상단 마이페이지 [아이콘]
            > [마이페이지]에서 구독 상품 항목에 있는 [결제 내역]을
            클릭하시면, 결제 관련 내용들을 보실 수 있습니다.`,
        faq_payment_question2: '해외 카드 결제 가능한가요?',
        faq_payment_answer2: `   아쉽게도 현재는 국내(한국)에서 발급된 신용/체크카드로만
              결제하실 수 있습니다. 추후 다양한 결제수단을 지원할 수 있도록
              부지런히 노력하겠습니다.`,
        faq_payment_question3: '법인 카드 결제 가능한가요?',
        faq_payment_answer3: `
              현재는 국내(한국)에서 발급된 개인 신용/체크카드로만 결제하실
              수 있습니다. 추후 업데이트 예정입니다.`,
        faq_inquiry: '이용문의',
        faq_inquiry_question1:
          '인공지능 활용한 글쓰기 서비스에 대해 제안하려면 어떻게 해야 하나요?',
        faq_inquiry_answer1: `
              멤버십에 가입하신 분들을 위해 새로운 서비스를 계속 준비하고
              있습니다. 제안하실 내용이 있으시면, 문의하기 또는 support@appplatform.co.kr
              로 메일을 보내주세요.`,
        faq_etc_heading: '궁금한 내용이 해결되지 않았다면?',
        faq_etc_description_chunk: '로 문의해주세요.',
      },
      contact: {
        title: '문의하기',
        description:
          "사용 중 불편한 내용이 있으시면 알려주세요. 근무일 기준 3일 내 답변 드리겠습니다. 라이팅젤 관련 질문은 버튼을 눌러 '자주 묻는 질문'을 참고해주세요!",
        faq_button: '자주 묻는 질문',
        type: '문의 유형',
        type_default: '문의 유형을 선택해주세요.',
        type_option1: '이용 문의',
        type_option2: '오류 신고',
        type_option3: '서비스 제안',
        type_option4: '환불',
        type_option5: '탈퇴',
        type_option6: '기타',
        type_error: '문의 유형을 선택해주세요!',
        inquiry_title: '문의 사항',
        inquiry_title_placeholder: '제목을 입력해주세요.',
        inquiry_text_placeholder: '문의 내용을 입력해주세요.',
        inquiery_text_error: '빈칸이 남아있습니다.',
        inquiry_of_error_notice: '오류 신고시 아래 내용을 함께 포함해주세요!',
        inquiry_of_error_text1:
          'PC에서 생긴 오류인가요? 아니면 Mobile에서 생긴 오류인가요?',
        inquiry_of_error_text2: '어떤 서비스를 사용 중에 오류가 발생했나요?',
        inquiry_of_error_text3:
          '에러 메세지를 함께 적어주시면 더 파악하기 쉬워요!',
        send_button: '보내기',
      },
      mypage: {
        title: '내 정보',
        identity: '라이팅젤 회원',
        nickname_label: '이름',
        id_label: '아이디',
        created_at_label: '가입날짜',
        writingel_label: '라이팅젤',
        writingel_subscribe_label: '라이팅젤 구독 개월수',
        writingel_no_membership: '멤버십을 결제하지 않은 회원입니다.',
        writingel_use_coupon_chunk1: '라이팅젤',
        writingel_use_coupon_chunk2: '개월 쿠폰',
        writingel_expired_date: '라이팅젤 사용 기간',
        month: '개월',
        none: '없음',
        membership_subscribe: '멤버십 구독',
        membership_not_subscribe: '멤버십을 구독하지 않은 회원입니다.',
        drawingel_label: '드로잉젤',
        chattingel_label: '채팅젤',
        period_of_use_label: '라이팅젤 이용기간',
        recently_payment_label: '결제 업데이트',
        not_recently_payment: '최근 결제 업데이트가 없는 회원입니다.',
        serial_number_coupon: '시리얼 넘버 쿠폰',
        kakao_pay: '카카오페이',
        inicis: '신용/체크카드 결제',
        innopay: '신용카드/체크카드',
        nopassbook: '무통장 입금 & 계좌 이체',
        payment_method_label: '결제 수단',
        coupon_label: '쿠폰 입력',
        coupon_input_placeholder: '쿠폰에 있는 시리얼 넘버를 입력해주세요.',
        coupon_button: '입력',
      },
      login: {
        description: '로그인 후 라이팅젤 서비스를 즐겨보세요',
        email_label: '이메일',
        email_required: '메일 주소를 적어주세요.',
        password_label: '비밀번호',
        password_required: '비밀번호를 적어주세요.',
        suggest_signup: '아직 회원이 아니신가요?',
        google_login: '구글 로그인',
        kakao_login: '카카오 로그인',
        facebook_login: '페이스북 로그인',
        agree_terms_chunk1: '이용약관',
        agree_terms_chunk2: '과',
        agree_terms_chunk3: '개인정보처리방침',
        agree_terms_chunk4: '을 확인하였고',
        agree_terms_chunk5: '동의합니다.',
        suggest_reset_password: '비밀번호를 잊으셨나요?',
        reset_password: '비밀번호 재설정',
      },
      reset_password: {
        title: '비밀번호 재설정',
        description: '비밀번호를 재설정할 이메일을 입력해주세요.',
        label: '이메일',
        reset: '재설정',
        sended_reset_email: '비밀번호 재설정 메일이 전송되었습니다.',
      },
      sidebar: {
        tingelbox_button: '팅젤 보관함',
        tingelbox_tooltip_label: '저장한 결과물을 여기에서 확인할 수 있습니다.',
        guide_button: '사용 가이드',
        chattingel: '채팅젤',
        drawingel: '드로잉젤',
        popular: '인기도구',
        storybook: '어린이 문학',
        storybook_intro: '동화책 출간하기',
        storybook_topic: '- 주제 선정하기',
        storybook_chapter: '- 챕터 만들기',
        storybook_story: '- 스토리 만들기',
        storybook_title: '- 제목 만들기',
        storybook_publish: '- 출간 하기',
        writing: '글쓰기',
        writing_mbti_loveletter: 'MBTI 연애편지',
        writing_first_sentence: '첫문장 자판기',
        writing_material: '글감찾기 질문 카드',
        writing_story_source: '이야기 재료 찾기',
        writing_fantasy_worldview: '판타지 세계관 생성',
        writing_lyrics: '시(가사) 쓰기',
        writing_diary: '일기 쓰기',
        writing_novel: '소설 쓰기',
        writing_novel_intro: '- 도입부 쓰기',
        writing_novel_continue: '- 이어쓰기',
        writing_fairytail: '동화쓰기',
        writing_fairytail_intro: '- 도입부 쓰기',
        writing_fairytail_continue: '- 이어쓰기',
        writing_next_sentence: '뒷문장 자판기',
        writing_coverletter: '대입 자소서 자동완성',
        marketing: '마케팅',
        marketing_newletter_editor: '뉴스레터 에디터',
        marketing_blog_writing: '블로그 글쓰기',
        marketing_blog_idea: '- 아이디어 정하기',
        marketing_blog_outline: '- 개요짜기',
        marketing_blog_intro: '- 도입부 쓰기',
        marketing_blog_continue: '- 이어쓰기',
        marketing_product_introduce: '상품 소개문구 & 상품후기',
        marketing_product_intro: '- 상품 소개문구 작성',
        marketing_product_review: '- 상품 후기 작성',
        business: '비즈니스',
        business_discussion: '찬반 논거',
        business_email_writing: '이메일 작성',
        business_email_summary: '이메일 요약',
        business_idea: '비즈니스 아이디어',
        business_resume: '취업 자소서 자동완성',
        office: '오피스',
        office_monthly_report: '월별 요약 보고',
        office_meeting_summary: '회의록 요약',
        office_holiday_announcements: '휴무 공지',
        office_formal_documentation: '공식 문서 작성',
        office_proposal_creation: '제안서 작성',
        writing_assistant: '글쓰기 보조도구',
        writing_assistant_writing_skill: '글쓰기 능력 향상',
        writing_assistant_spell_checker: '맞춤법 검사기',
        writing_assistant_vocabular_correction: '어휘력 교정기',
        writing_assistant_dialect_conversion: '사투리 변환기',
        writing_assistant_objective_subjective: '객관적/주관적 관점 변환기',
        foreign_language: '외국어',
        foreign_language_word_master: '영단어 마스터',
      },
      tingelbox: {
        recently_saved_contents: '최근 저장된 콘텐츠',
        maximum_saved: '_최대 10개까지 저장됩니다.',
        copy_button: '복사',
        delete_button: '삭제',
        empty_box: '보관된 내용이 없습니다!',
        go_to_service: '서비스 이용하러 가기',
      },
      signup: {
        description:
          '라이팅젤에 가입 하시면 더 많은 서비스를 즐기실 수 있어요!',
        google: '구글로 시작하기',
        kakao: '카카오로 시작하기',
        facebook: '페이스북으로 시작하기',
        email: '이메일로 시작하기',
        terms_of_service_chunk1: '이용약관',
        terms_of_service_chunk2: '과',
        terms_of_service_chunk3: '개인정보처리방침',
        terms_of_service_chunk4: '을 확인하였고 동의합니다.',
        suggest_login: '이미 라이팅젤 회원이신가요? ',
      },
      service: {
        novel_intro_slogan: '웹소설 창작의 시작',
        blog_idea_slogan: '매력적인 블로그 콘텐츠',
        fairy_intro_slogan: '함께 만드는 동화',
        first_sentence_slogan: '독자를 사로잡는 첫 문장',
        lyrics_slogan: '자유로운 창작의 시와 가사',
        business_slogan: '창의적인 비즈니스 제안',
        mbti_loveletter_slogan: 'MBTI로 표현하는 사랑의 감정',
        discussion_slogan: '균형 잡힌 논리 전개',
        material_slogan: '파일상에서 영감을 발견하세요',
        storysrc_slogan: '이야기의 시작을 찾아서',
        coverletter_slogan: '간편한 대입 자소서 작성',
        newsletter_slogan: '뉴스레터 쉽게 만들기',
        product_intro_slogan: '인상적인 상품 설명과 후기',
        fantasy_slogan: '쉬운 판타지 세계 창조',
        next_sentence_slogan: '문장 이어쓰기의 도움',
        email_slogan: '전문적인 이메일 스킬',
        resume_slogan: '취업을 위한 자소서 강화',
        diary_slogan: '일상을 기록하는 즐거움',
        chattingel_system_message: '채팅을 시작합니다.',
        chattingel_reset: '채팅 기록 초기화',
        chattingel_to_drawingel: '해당 결과 드로잉젤에 사용',
        chattingel_input_placeholder: '채팅 입력창',
        chattingel_input_voice_loading_placeholder: '음성 입력 중입니다.',
        chattingel_send: '전송',
        chattingel_reset_message: '정말 모든 채팅 기록을 삭제하시겠습니까?',
        chattingel_remove: '삭제',
        chattingel_cancel: '취소',
        chattingel_welcome: '📢 채팅젤에 오신 걸 환영합니다! 🥳',
        chattingel_introduce:
          '📢 채팅젤을 사용해 궁금한 점을 물어보세요! 질문과 답이 저장되어 언제든지 다시 볼 수 있습니다.',
        chattingel_please_start: '채팅을 시작해주세요.',
        drawingel_type: '회화 종류',
        drawingel_type_default: '원하는 회화 종류를 선택해주세요',
        drawingel_type_modernism: '모더니즘',
        drawingel_type_abstract_art: '추상화',
        drawingel_type_cubism: '입체주의',
        drawingel_type_pop_art: '팝아트',
        drawingel_type_Impressionism: '인상파',
        drawingel_type_romanticism: '낭만주의',
        drawingel_type_illust: '일러스트',
        drawingel_material: '회화 재료',
        drawingel_material_default: '원하는 회화 재료를 선택해주세요.',
        drawingel_material_watercolor: '수채화',
        drawingel_material_oil_paint: '유화',
        drawingel_material_crayon: '크레파스',
        drawingel_material_acrylic_paint: '아크릴 물감',
        drawingel_material_pastel: '파스텔',
        drawingel_material_enamel: '에나멜 물감',
        drawingel_storybook: '상황별 이미지 만들기',
        drawingel_storybook_placeholder:
          '해당하는 옵션이 있는 경우 선택해주세요.',
        drawingel_storybook_cover_page: '책 표지 만들기',
        drawingel_storybook_story: '챕터 별 속이미지 만들기',
        drawingel_stroybook_sequence: `💡 속이미지 만들기 순서,
1. 챕터 1의 내용을 입력한 후, 해당 챕터의 속이미지를 생성합니다. 
2. 이 과정을 챕터 10까지 순차적으로 반복하여 각 챕터별 속이미지를 만듭니다.`,
        drawingel_model: '사용 모델',
        drawingel_model_placeholder: '그리기 모델 종류를 선택하세요',
        drawingel_image_size: '사이즈',
        drawingel_image_count: '이미지 개수를 설정해주세요!',
        drawingel_repaint: '새로 그리기',
        drawingel_yes: '확인',
        drawingel_not_yet_chunk1: '아직 이미지가',
        drawingel_not_yet_chunk2: '만들어지지 않았어요!',
        drawingel_modal_header: 'AI가 그릴 텍스트를 확인해주세요!',
        drawingel_modal_spare: '그림 그리기 (남은 토큰 : ',
        drawingel_cancel: '취소',

        holiday_announcements_title: '휴무 공지',
        holiday_announcements_tooltip:
          '회사의 공휴일, 특별 휴가, 그리고 예정된 휴무에 관한 공지사항을 작성해드립니다.',
        holiday_announcements_description:
          '휴무 날짜, 적용 범위, 그리고 필요한 경우 대체 근무일에 대한 정보를 작성해주세요.',
        holiday_announcements_days_label: '휴무 날짜',
        holiday_announcements_days_placeholder:
          "ex) '2024년 5월 1일' 또는 '2024년 12월 25일 - 26일'",
        holiday_announcements_reason_label: '휴무 사유',
        holiday_announcements_reason_placeholder:
          '휴무가 지정된 이유나 배경을 작성해 주십시오.',
        holiday_announcements_range_label: '적용 범위',
        holiday_announcements_range_placeholder:
          '휴무 공지가 적용되는 대상을 명시해 주십시오. ex) 모든 직원, 특정 부서, 지역 사무소',
        holiday_announcements_alternate_workday_label: '대체 근무일',
        holiday_announcements_alternate_workday_placeholder:
          '해당 휴무에 대한 대체 근무일이 있는 경우, 그 날짜와 관련 근무 조정 내용을 기술해 주십시오.',
        holiday_announcements_extra_holiday: '특별 휴가 및 예정된 휴일',
        holiday_announcements_extra_placeholder:
          '특별 휴가나 예정된 추가 휴무가 있는 경우, 그 날짜와 해당 휴가의 성격을 설명해 주십시오.',
        holiday_announcements_write_button: '글쓰기',
        draw_modal_purpose_download: '이미지를 선택 후 저장할 수 있습니다!',
        draw_modal_purpose_paint_chunk1: '방금 나온 결과물에 대한',
        draw_modal_purpose_paint_chunk2: ' 이미지를 생성 하겠습니까?',
        draw_modal_more_paint: '더 만들고 싶나요?',
        draw_modal_paint: '이미지 만들기',
        draw_modal_membership_subscribe: '멤버십 가입하기',
        draw_modal_model_creation: '로 만들기',
        draw_modal_model_select_title: '어떤 그림 스타일을 원하세요?',
        draw_modal_dalle3_story_description: '동화책에 어울리는 산뜻한 이미지',
        draw_modal_flux_story_description: '입체적이고 세련된 이미지',
        lyrics_title: '시(가사) 쓰기',
        lyrics_title_tooltip:
          '시 제목과 주제어 3개 이상을 쓰고 버튼을 누르면 시/가사가 만들어집니다.',
        lyrics_description:
          '시 제목과 가사에 담고 싶은 주제어를 3개 이상 입력하세요.',
        lyrics_keyword_input_placeholder: '키워드를 적어주세요!(최대 10글자)',
        lyrics_write_button: '글쓰기',
        check_membership_modal_text1: '무료 사용이 끝났습니다.',
        check_membership_modal_text2:
          '멤버십 가입을 통해 이용하실 수 있습니다.',
        check_membership_modal_go_to_membership: '멤버십 가입하기',
        meeting_summary_title: '회의록 요약',
        meeting_summary_title_tooltip:
          '회의의 핵심 내용을 전문적으로 분석하여, 중요한 토론 주제, 합의된 행동 계획, 그리고 예정된 후속 회의 주제들을 체계적으로 요약합니다.',
        meeting_summary_description: '요약할 회의록을 입력해 주세요.',
        meeting_summary_button: '요약하기',
        email_summary_title: '이메일 요약',
        email_summary_title_tooltip:
          '이메일 본문을 입력해주시면 간결하게 요약해드립니다.',
        email_summary_description: '이메일 본문을 입력하세요.',
        email_summary_button: '요약하기',
        storybook_example_title: '출간 동화책 예시',
        storybook_example1_description:
          '신비로운 숲과 그 속에 숨겨진 마법의 동물들 이야기',
        storybook_example2_description:
          '주인공이 마법의 지팡이를 얻어 별이 반짝이는 밤하늘 아래 모험을 떠나는 이야기',
        storybook_example3_description:
          '비밀의 정원을 탐험하면서 다양한 종류의 드래곤들을 만나는 어린 탐험가의 이야기',
        storybook_start: '동화책 만들기 지금 바로 시작',
        storybook_topic_title: '동화책 출간하기_주제 선정하기',
        storybook_topic_title_tooltip:
          '동화책을 만들기 위한 첫 걸음은 주제를 선정하는 것입니다. 주제 선정에 도움을 드립니다.',
        storybook_topic_category_label:
          '동화책에서 다루고 싶은 이야기의 핵심 주제를 선택해주세요.',
        storybook_topic_category_placeholder: '카테고리를 선택해주세요.',
        storybook_topic_category_courage: '우정',
        storybook_topic_category_adventure: '모험',
        storybook_topic_category_nature: '자연',
        storybook_topic_category_fantasy: '판타지',
        storybook_topic_category_mystery: '추리',
        storybook_topic_category_etc: '기타',
        storybook_topic_category_etc_input_placeholder:
          '핵심 주제를 간략히 작성해주세요.',
        storybook_topic_message_label:
          '선택한 주제에 기반하여, 동화책을 통해 전달하고 싶은 교훈이나 메시지는 무엇인가요?',
        storybook_topic_tip_title: '주제 선정하기 Tip',
        storybook_topic_tip_sequence1_title: '1. 동화책 장르 선택하기.',
        storybook_topic_tip_sequence1_description:
          '동화책의 장르를 정하는 것은 이야기의 방향과 분위기를 설정하는 데 도움이 됩니다. 우정, 모험, 자연, 판타지, 추리 등 다양한 장르 중에서 이야기의 주제와 가장 잘 맞는 것을 선택하세요. 장르를 선택하면 해당 장르에 어울리는 캐릭터, 설정, 플롯을 구상하기 더 쉬워집니다.',
        storybook_topic_tip_sequence2_title: '2. 동화책에 교훈 담기.',
        storybook_topic_tip_sequence2_description:
          '동화책에는 읽는 이에게 전달하고 싶은 교훈이나 메시지가 포함되어야 합니다. 이야기를 통해 어떤 가치나 교훈을 전달하고 싶은지 고민해보세요. 예를 들어, 우정의 중요성, 용기, 솔직함 같은 주제를 다룰 수 있습니다.',
        storybook_topic_next_step: '주제가 마음에 드신다면 클릭해주세요.',
        storybook_topic_next_step_alert:
          '출력된 주제가 자동 입력되며, 수정이 가능합니다.',
        storybook_topic_next_step_button: '스토리 만들기로 이동',
        storybook_chapter_title: '동화책 출간하기_챕터 만들기',
        storybook_chapter_tooltip:
          '창의적인 챕터 10가지를 추천해 드리겠습니다.',
        storybook_chapter_description: '동화책의 주제나 메시지를 입력해주세요.',
        storybook_chapter_next_step: '챕터가 마음에 드신다면 클릭해주세요.',
        storybook_chapter_next_step_alert:
          '출력된 챕터가 자동 입력되며, 수정이 가능합니다.',
        storybook_chapter_next_step_button: '스토리 만들기로 이동',
        storybook_chapter_tip_title: '챕터 만들기 Tip',
        storybook_chapter_tip_sequence1_title: '1. 주제와 교훈을 명확히 하기.',
        storybook_chapter_tip_sequence1_description1:
          '동화책의 중심이 될 주제와 교훈을 명확하게 정의하세요.',
        storybook_chapter_tip_sequence1_description2:
          '이는 챕터들을 구성하는 데 있어 기반을 제공하며, 각 챕터가 전체적인 메시지와 일관성을 유지하도록 돕습니다.',
        storybook_chapter_tip_sequence2_title:
          '2. 주제에 대한 세부 사항 추가하기.',
        storybook_chapter_tip_sequence2_description1:
          '각 주제의 교훈과 함께 구체적인 내용을 제시하세요.',
        storybook_chapter_tip_sequence2_description2:
          "예를 들어 '우정'이라는 주제를 다룬다면, 우정의 중요성, 우정이 시련을 겪을 때의 상황, 그리고 우정을 통해 어려움을 극복하는 방법 등에 대해 자세히 설명할 수 있습니다.",
        storybook_story_title: '동화책 출간하기_챕터 별 스토리',
        storybook_story_tooltip:
          "모든 챕터의 스토리를 완성한 뒤, '저장하기' 버튼을 클릭해 이야기를 PC에 워드 문서로 저장해 주세요. 그다음 각 챕터에 해당하는 이미지를 생성하고, 생성된 이미지를 저장한 후, 워드 문서에 해당 챕터의 스토리에 이미지를 삽입해 주세요.",
        storybook_story_description:
          '각 챕터의 스토리가 완성 된 후 속이미지를 생성해주세요.',
        storybook_story_create: '🫧 아직 스토리가 \n만들어지지 않았어요!',
        storybook_story_download_button: '저장하기',
        storybook_title_title: '동화책 출간하기_제목 선정하기',
        storybook_title_tooltip:
          '스토리를 바탕으로 매력적인 제목 10가지를 구상해 드리겠습니다.',
        storybook_title_description:
          '스토리를 입력해주세요.(1~10 챕터 까지 모든 스토리를 입력해주세요.)',
        storybook_title_tip: '표지 이미지 만들기 이용방법',
        // storybook_image_prompt: '어린이 동화 스타일의 디즈니 그림처럼 {{text}} 내용에 맞게 이미지를 그려줘. 이미지에 텍스트는 들어가면 안돼 ',
        storybook_title_tip_sequence1:
          '1. 10가지 제목 중 마음에 드는 제목을 선택하여 복사합니다.',
        storybook_title_tip_sequence2:
          '2. "동화책 표지 만들기" 버튼을 클릭하여 표지 제작 페이지로 이동합니다.',
        storybook_title_tip_sequence3:
          '3. 표지 디자인이 완성되면 포토샵을 활용하여 앞서 선택한 제목을 표지에 삽입합니다.',
        storybook_title_tip_draw_message: '동화책 표지 만들기',
        storybook_publication_title: '동화책 출간하기_출간하기',
        storybook_publication_tooltip: '직접 창작한 동화를 출판해보세요',
        storybook_publication_text1: '💡 동화책 출간하기 방법',
        storybook_publication_text2: '이메일: support@appplatform.co.kr',
        storybook_publication_text3:
          '완성된 워드 문서를 위 주소로 전송해 주세요.',
        storybook_publication_text4: '메일 전송 전 확인 사항',
        storybook_publication_text5:
          '* 워드 문서 첫 페이지에 출간에 필수적인 정보를 반드시 포함시켜 주세요.',
        storybook_puclication_text6:
          '* 책의 표지와 각 장(챕터)에 들어갈 삽화 이미지들도 문서 안에 첨부해 주세요.',
        mbti_loveletter_title: 'MBTI 연애편지',
        mbti_loveletter_tooltip:
          '본인과 상대의 MBTI를 선택한 후 버튼을 클릭하면 AI가 러브레터를 써줍니다.',
        mbti_loveletter_from_label:
          '연애편지를 보내는 사람의 MBTI를 선택하세요.',
        mbti_loveletter_to_label: '연애편지를 받을 사람의 MBTI를 선택하세요.',
        mbti_loveletter_confirm: '위 사항이 맞나요?',
        mbti_loveletter_reset: '다시 선택',
        mbti_loveletter_write: '편지 쓰기',
        first_sentence_title: '첫문장 자판기',
        first_sentence_tooltip:
          '버튼을 클릭하면 글의 첫문장을 만들 수 있습니다.',
        first_sentence_description: '쓰려고 하는 글의 장르를 선택하세요',
        first_sentence_mystery: '추리',
        first_sentence_martial: '무협',
        first_sentence_fantasy: '판타지',
        first_sentence_childrenstory: '어린이 동화',
        first_sentence_teenager: '청소년',
        first_sentence_thriller: '스릴러',
        first_sentence_SF: '공상과학',
        first_sentence_shortfiction: '단편소설',
        first_sentence_bookreview: '독후 감상문',
        first_sentence_moviereview: '영화 감상문',
        first_sentence_travel: '여행 에세이',
        first_sentence_autobio: '자서전',
        first_sentence_loveletter: '연애편지',
        material_title: '글감찾기 질문카드',
        material_tooltip: '카드를 누르면 글감을 받아볼 수 있습니다.',
        material_description:
          '글감을 찾기 위한 질문이 적힌 카드를 하나 뽑으세요.',
        storysrc_title: '이야기 재료 찾기',
        storysrc_tooltip:
          '이야기로 쓸 단어를 뽑고, 해당 단어들로 짧은 이야기를 만들 수 있습니다.',
        storysrc_generate: '이야기 재료로 쓸 단어 뽑기',
        storysrc_regenerate: '다시 뽑기 & 다시 쓰기',
        fantasy_title: '판타지 세계관 생성',
        fantasy_title_tooltip:
          '버튼을 클릭하면 장르에 맞는 판타지 세계관을 만들 수 있습니다.',
        fantasy_description:
          '쓰려고 하는 판타지 소설의 세부 장르를 선택하세요.',
        fantasy_world: '일반 판타지',
        fantasy_world_dark: '다크 판타지',
        fantasy_world_modern: '현대 판타지',
        fantasy_world_history: '역사 판타지',
        fantasy_world_science: '과학 판타지',
        fantasy_world_space: '우주 판타지',
        fantasy_world_romantic: '로맨틱 판타지',
        diary_title: '일기 쓰기',
        diary_title_tooltip:
          '주제어 3개 이상을 쓰고 버튼을 누르면 일기가 만들어집니다.',
        diary_description: '일기에 쓸 내용의 키워드를 3개 이상 입력하세요.',
        diary_inputlist_placeholder: '키워드를 적어주세요!',
        diary_write: '글쓰기',
        novel_intro_title: '소설 쓰기_도입부 쓰기',
        novel_intro_title_tooltip:
          '주요인물/장소/시간/주요 사건을 넣고 버튼을 누르면 소설의 도입부를 뽑아볼 수 있습니다.',
        novel_intro_description: '소설의 기본 요소를 입력해주세요.',
        novel_intro_pov_label: '시점',
        novel_intro_pov_placeholder: '시점을 선택해주세요!',
        novel_intro_pov_option1: '1인칭 객관적 시점',
        novel_intro_pov_option2: '1인칭 주관적 시점',
        novel_intro_pov_option3: '2인칭 시점',
        novel_intro_pov_option4: '3인칭 객관적 시점',
        novel_intro_pov_option5: '3인칭 주관적 시점',
        novel_intro_style_label: '문체',
        novel_intro_style_placeholder: '문체를 선택해주세요!',
        novel_intro_style_option1: '경쾌한',
        novel_intro_style_option2: '현란한',
        novel_intro_style_option3: '강건한',
        novel_intro_style_option4: '우아한',
        novel_intro_style_option5: '부드러운',
        novel_intro_style_option6: '서술적인',
        novel_intro_style_option7: '명료한',
        novel_intro_main_character_label: '주요 인물',
        novel_intro_main_character_placeholder: 'ex) 팅젤이',
        novel_intro_place_label: '장소',
        novel_intro_place_placeholder: 'ex) 서울',
        novel_intro_time_label: '시간',
        novel_intro_time_placeholder: 'ex) 2042년',
        novel_intro_main_events_label: '주요 사건',
        novel_intro_main_events_placeholder: 'ex) 시간여행',
        novel_intro_write: '글쓰기',
        novel_intro_next_step: '글이 마음에 드시나요?',
        novel_intro_modal_alert: '완성된 도입부에 이어서 쓰고 싶나요?',
        novel_intro_modal_continue: '이어쓰기',
        novel_continue_title: '소설 쓰기_이어쓰기',
        novel_continue_title_tooltip:
          '소설 도입부를 쓰고 버튼을 클릭하면 AI가 글을 이어서 써줍니다.',
        novel_continue_description:
          '소설의 도입부를 써주세요.(최소 100자 이상)',
        novel_continue_continue: '완성된 글 넣어서 이어쓰기',
        novel_continue_write: '글쓰기',
        fairy_intro_title: '동화 쓰기_도입부 쓰기',
        fairy_intro_tooltip:
          '주요인물/장소/시간/주제/주요사건을 넣고 버튼을 누르면 동화의 도입부를 뽑아볼 수 있습니다.',
        fairy_intro_description: '동화의 기본 요소를 입력해주세요.',
        fairy_intro_main_character_label: '주요 인물',
        fairy_intro_main_character_placeholder: 'ex) 팅젤이',
        fairy_intro_place_label: '장소',
        fairy_intro_place_placeholder: 'ex) 서울',
        fairy_intro_time: '시간',
        fairy_intro_time_placeholder: 'ex) 2042년',
        fairy_intro_topic: '주제',
        fairy_intro_topic_placeholder: 'ex) 우정',
        fairy_intro_main_events_label: '주요 사건',
        fairy_intro_main_events_placeholder: 'ex) 시간여행',
        fairy_intro_write: '글쓰기',
        fairy_intro_next_step: '글이 마음에 드시나요?',
        fairy_intro_modal_alert: '완성된 도입부에 이어서 쓰고 싶나요?',
        fairy_intro_modal_continue: '이어 쓰기',
        fairy_continue_title: '동화 쓰기_이어쓰기',
        fairy_continue_title_tooltip:
          '동화 도입부를 쓰고 버튼을 클릭하면 AI가 글을 이어서 써줍니다.',
        fairy_continue_description:
          '동화의 도입부를 써주세요.(최소 100자 이상)',
        fairy_continue_write: '글쓰기',
        fairy_continue_continue: '완성된 글 넣어서 이어쓰기',
        next_sentence_title: '뒷문장 자판기',
        next_sentence_label:
          '첫문장을 입력 후 버튼을 누르면 AI가 뒷문장을 만들어 줍니다.',
        next_sentence_description:
          '글을 쓰다가 막힌 부분의 마지막 문장을 입력하세요.',
        next_sentence_write: '글쓰기',
        coverletter_title: '대입 자소서 자동 완성',
        coverletter_title_tooltip:
          '질문을 선택하고 전공을 입력한 후 버튼을 누르면 AI가 대입 자소서를 만들어줍니다.',
        coverletter_common_question_label: '공통질문을 선택하세요',
        coverletter_common_question_placeholder: '질문을 선택해주세요',
        coverletter_common_question_option1:
          '1. 진로 관련 학습 경험 및 교내활동',
        coverletter_common_question_option2:
          '2. 타인과 공동체를 위한 노력한 경험',
        coverletter_common_question_option3:
          '3. 지원동기와 지원하기 위해 노력한 과정',
        coverletter_major_label: '지원하는 전공을 입력하세요',
        coverletter_major_placeholder: 'ex) 신소재공학과, 문예창작과 등',
        coverletter_experience_label: '경험을 입력하세요(선택사항)',
        coverletter_experience_placeholder:
          'ex) 봉사 활동 or 동아리 활동 등 교내외 활동',
        coverletter_write: '글쓰기',
        newsletter_title: '뉴스레터 에디터',
        newsletter_title_tooltip:
          '빈 칸을 채우고 다음/완성 버튼을 누르면 순서대로 뉴스레터에 필요한 내용을 채울 수 있습니다.',
        newsletter_from_label: '뉴스레터 발신인',
        newsletter_from_placeholder: 'ex)라이팅젤',
        newsletter_to_label: '뉴스레터 수신인',
        newsletter_to_placeholder: '구독자/가입회원/멤버십',
        newsletter_name_label: '뉴스레터 이름',
        newsletter_name_placeholder: 'ex) 팅젤레터',
        newsletter_purpose_label: '뉴스레터 발송 목적',
        newsletter_purpose_placeholder: 'ex) 인공지능 글쓰기 라이팅젤 홍보',
        newsletter_next: '다음',
        newsletter_prev: '뒤로',
        newsletter_begin: '처음으로',
        newsletter_topic_label: '이번 호 주제',
        newsletter_topic_placeholder: 'ex)인공지능으로 시 쓰기',
        newsletter_main_content_label: '이번 호 주요내용',
        newsletter_auto_generate: '자동 완성',
        newsletter_download_tooltip: '결과를 저장할 수 있습니다.',
        newsletter_regenerate_tooltip: '누르면 새로운 결과물이 나타납니다.',
        newsletter_main_title_label: '이번 호 제목',
        newsletter_lead_paragraph_label: '이번 호 리드글',
        blog_idea_title: '블로그 쓰기_아이디어 정하기',
        blog_idea_tooltip:
          '작성할 블로그 주제나 키워드를 입력하면 관련 아이디어를 뽑아볼 수 있습니다.',
        blog_idea_description: '작성할 블로그의 주제나 키워드를 입력하세요.',
        blog_idea_placeholder: 'ex) 판타지 소설 잘 쓰는 법',
        blog_idea_write: '글쓰기',
        blog_idea_next_step: '아이디어가 마음에 드시나요?',
        blog_idea_next_step_modal_alert:
          '이 아이디어를 활용해 다음 단계로 넘어가볼까요?',
        blog_idea_next_step_modal_outline_write: '개요 짜기',
        blog_idea_next_step_modal_intro_write: '도입부 쓰기',
        blog_outline_title: '블로그 쓰기_개요 짜기',
        blog_outline_title_tooltip:
          '작성할 블로그 주제나 아이디어를 입력하면 관련 개요를 뽑아볼 수 있습니다.',
        blog_outline_description:
          '작성할 블로그의 주제나 아이디어를 입력하세요.',
        blog_outline_placeholder:
          'ex) 판타지 소설을 쓸 때 해야 할 것과 하지 말아야 할 것',
        blog_outline_write: '글쓰기',
        blog_intro_title: '블로그 쓰기_도입부 쓰기',
        blog_intro_title_tooltip:
          '작성할 블로그 주제나 아이디어를 입력하면 도입부를 뽑아볼 수 있습니다.',
        blog_intro_description: '작성할 블로그의 주제나 아이디어를 입력하세요.',
        blog_intro_placeholder:
          'ex) 판타지 소설을 쓸 때 해야 할 것과 하지 말아야 할 것',
        blog_intro_write: '글쓰기',
        blog_intro_next_step: '글이 마음에 드시나요?',
        blog_intro_modal_alert: '완성된 도입부에 이어서 쓰고 싶나요?',
        blog_intro_modal_continue: '이어 쓰기',
        blog_follow_title: '블로그 쓰기_이어쓰기',
        blog_follow_title_tooltip:
          '블로그 도입부를 쓰고 버튼을 클릭하면 AI가 글을 이어서 써줍니다.',
        blog_follow_description:
          '블로그의 도입부를 써주세요. (최소 100자 이상)',
        blog_follow_write: '글쓰기',
        blog_follow_continue: '완성된 글 넣어서 이어쓰기',
        product_intro_title: '소개 문구 작성',
        product_intro_tooltip:
          '제품의 종류/이름/특징을 채우고 버튼을 누르면 상품 소개 문구가 나옵니다.',
        product_intro_type_label: '소개문구를 작성할 제품의 종류를 입력하세요.',
        product_intro_type_placeholder: 'ex)인공지능 글쓰기 프로그램',
        product_intro_name_label: '소개문구를 작성할 제품의 이름을 입력하세요.',
        product_intro_name_placeholder: 'ex)라이팅젤',
        product_intro_feature_label:
          '소개문구를 작성할 제품의 특징을 입력하세요.',
        product_intro_feature_placeholder: 'ex) 간편하고 빠름',
        product_intro_write: '글쓰기',
        product_review_title: '후기 작성',
        product_review_title_label:
          '제품의 종류/이름/특징을 채우고 버튼을 누르면 상품 후기가 나옵니다.',
        product_review_type_label: '후기를 작성할 제품의 종류를 입력하세요.',
        product_review_type_placeholder: 'ex)인공지능 글쓰기 프로그램',
        product_review_name: '후기를 작성할 제품의 이름을 입력하세요.',
        product_review_name_placeholder: 'ex)라이팅젤',
        product_review_feature_label: '후기를 작성할 제품의 특징을 입력하세요.',
        product_review_feature_placeholder: 'ex) 간편하고 빠름',
        product_review_write: '글쓰기',
        discussion_title: '찬반 논거',
        discussion_title_label:
          '토론하고 싶은 주제를 입력하고 버튼을 누르면 찬성과 반대의견이 나옵니다.',
        discussion_description: '토론하고자 하는 주제를 입력하세요.',
        discussion_placeholder: 'ex) 인터넷 실명제 도입',
        discussion_write: '글쓰기',
        email_title: '이메일 작성',
        email_tooltip:
          '이메일 관련 정보들을 입력 및 선택하고 버튼을 누르면 이메일을 작성해드립니다.',
        email_from_label:
          '이메일 발신자(개인/기업/프로젝트/서비스 등)의 이름을 입력하세요.',
        email_from_placeholder: 'ex) 라이팅젤',
        email_from_description_label:
          '위에 입력한 이메일 발신자에 대해 간단한 설명을 입력하세요.',
        email_from_description_placeholder: 'ex) 인공지능 기반 글쓰기 서비스',
        email_to_label:
          '이메일 수신자(개인/기업/프로젝트/서비스 등)를 입력하세요.',
        email_to_placeholder: 'ex)고객',
        email_purpose_label: '이메일을 작성하는 목적을 작성해주세요.',
        email_purpose_placeholder: 'ex) 다음 회의 세부사항을 의논하기 위해',
        email_purpose_style_label: '어떤 어조를 원하는지 선택해주세요.',
        email_purpose_style_placeholder: '이메일 어조를 선택해주세요',
        email_purpose_style_option1: '설득하는',
        email_purpose_style_option2: '위계적인',
        email_purpose_style_option3: '밝은',
        email_purpose_style_option4: '사무적인',
        email_purpose_style_option5: '차분한',
        email_purpose_style_option6: '재치있는',
        email_purpose_style_option7: '친근한',
        email_purpose_style_option8: '세심한',
        email_write: '글쓰기',
        business_idea_title: '비즈니스 아이디어',
        business_idea_tooltip:
          '비즈니스로 이루고 싶은 목적을 입력한 후 버튼을 클릭하면 관련 아이디어를 나옵니다',
        business_idea_purpose: '비즈니스로 이루고 싶은 목적을 입력하세요.',
        business_idea_placeholder: 'ex) 인공지능 기술 활용, 비대면 운동 증진',
        business_idea_write: '글쓰기',
        resume_title: '취업 자소서 자동 완성',
        resume_title_tooltip:
          '빈 칸을 채우고 버튼을 누르면 AI가 자소서를 만들어줍니다.',
        resume_company_label: '지원하는 회사명을 입력하세요.',
        resume_company_placeholder: 'ex) 주식회사 라이팅젤',
        resume_competency_label: '지원하는 회사의 인재상을 입력하세요.',
        resume_competency_placeholder: 'ex) 모험정신, 창의성, 유연성',
        resume_position_label: '지원하는 직무에 대해서 입력하세요.',
        resume_position_placeholder: 'ex) 영업직 or 프론트엔드 개발',
        resume_question_label: '자기소개서 질문을 입력하세요.',
        resume_question_placeholder:
          'ex) 지금까지 살면서 힘들었던 경험과 그것을 어떻게 극복했는지를 서술하시오',
        resume_experience_label: '이력을 입력하세요.(선택사항)',
        resume_experience_placeholder: 'ex) 교내외 활동 or 인턴/재직 경험',
        resume_write: '글쓰기',
        monthly_report_title: '월별 요약 보고',
        monthly_report_title_tooltip:
          '매달의 주요 성과, 진행 상황, 그리고 다가오는 계획에 대해 요약하는 보고서를 작성합니다.',
        monthly_report_description:
          '보고서를 작성하기 위해 필요한 핵심 요소들을 명확히 기재해 주세요.',
        monthly_report_period_label: '보고 기간',
        monthly_report_period_placeholder:
          'ex) 2024년 1월 2일 ~ 2023년 1월 31일',
        monthly_report_current_progress_label: '현재 진행 상황',
        monthly_report_current_progress_placeholder:
          '현재 진행 중인 프로젝트나 업무의 상태에 대한 정보입니다. \n예를 들어, 진행 중인 작업, 당면한 과제, 진행 상황에 대한 간략한 개요가 필요합니다.',
        monthly_report_key_achivement_label: '주요 성과',
        monthly_report_key_achivement_placeholder:
          '해당 기간 동안 달성한 주요 성과나 완료된 주요 작업에 대한 정보가 필요합니다. \n성취한 목표, 완료된 프로젝트, 획득한 성과 등을 포함할 수 있습니다.',
        monthly_report_future_plans_label: '향후 계획',
        monthly_report_futre_plans_placeholder:
          '다음 달 또는 다음 기간에 대한 계획이나 목표입니다. \n이는 향후 계획, 목표 설정, 준비해야 할 사항 등을 포함할 수 있습니다.',
        monthly_report_challenges_improvements_label: '문제점 및 개선 사항',
        monthly_report_challenges_improvements_placeholder:
          '기간 동안 직면한 문제점, 도전 과제 및 이를 해결하기 위한 제안이나 개선 방안에 대한 내용입니다.',
        monthly_report_write: '글쓰기',
        formal_documentation_title: '공식 문서 작성',
        formal_documentation_title_tooltip:
          '회사의 공식적인 문서 작성을 위한 것으로, 정책 변경, 중요한 회사 결정, 또는 다른 공식적인 사항에 대한 글을 작성하는 데 사용됩니다',
        formal_documentation_description:
          '명확하고 공식적인 언어 사용이 중요합니다.',
        formal_documentation_purpose_label: '문서의 목적',
        formal_documentation_purpose_placeholder:
          '이 문서의 주된 목적이 무엇인지 설명해 주십시오. ex) 정책 변경, 회사 결정 통보, 공지사항',
        formal_documentation_key_content_label: '주요 내용',
        formal_documentation_key_contnet_placeholder:
          '문서에 포함될 주요 내용이나 메시지는 무엇인지 상세하게 기술해 주십시오.',
        formal_documentation_target_label: '대상자',
        formal_documentation_target_placeholder:
          '이 문서가 전달될 대상자는 누구인지 명시해 주십시오. ex) 모든 직원, 특정 부서, 경영진',
        formal_documentation_background_label: '문서 배경',
        formal_documentation_background_placeholder:
          '문서의 내용과 관련된 배경 정보나 문맥을 제공해 주십시오. ex) 왜 이러한 결정이 내려졌는지, 이전의 관련 사항들',
        formal_documentation_requirements_label: '지침 및 요구사항',
        formal_documentatoin_requirements_placeholder:
          '문서를 읽는 대상자가 취해야 할 구체적인 행동이나 지침이 있는 경우, 그 내용을 포함시켜 주십시오.',
        formal_documentation_deadlines_label: '중요 일정 및 마감',
        formal_documentation_deadlines_placeholder:
          '문서의 유효 기간이나 특별히 중요한 날짜들이 있다면 명시해 주십시오.',
        formal_documentation_write: '글쓰기',
        proposal_creation_title: '제안서 작성',
        proposal_creation_title_tooltip:
          '새로운 프로젝트, 제품, 서비스 또는 회사 내부의 제안을 위한 문서를 작성해드립니다.',
        proposal_creation_description:
          '제안의 목적, 예상되는 이익, 필요한 자원 및 실행 계획을 상세히 기술해야 합니다.',
        proposal_creation_purpose_label: '제안의 목적',
        proposal_creation_purpose_placeholder:
          '제안의 기본 목적이 무엇인지 설명해 주십시오. ex) 새로운 프로젝트, 제품, 서비스, 또는 특정 변화에 대한 제안',
        proposal_creation_expected_benefits_label: '예상되는 이익',
        proposal_creation_expected_benefits_placeholder:
          '제안을 통해 기대하는 이익이나 결과에 대해 구체적으로 기술해 주십시오. 이익은 비용 절감, 수익 증대, 효율성 향상 등 다양한 형태가 될 수 있습니다.',
        proposal_creation_resource_label: '필요한 자원',
        proposal_creation_resource_placeholder:
          '제안 실행을 위해 필요한 자원에 대해 설명해 주십시오. 이는 재정적 자원, 인적 자원, 기술, 장비 등을 포함할 수 있습니다.',
        proposal_creation_plan_label: '실행 계획',
        proposal_creation_plan_placeholder:
          '제안을 실행하기 위한 계획을 상세하게 제시해 주십시오. 이 계획에는 단계별 작업, 예상되는 시간표, 필요한 조치들이 포함될 수 있습니다.',
        proposal_creation_mitigation_label: '위험 및 대응 전략',
        proposal_creation_mitigation_placeholder:
          '제안과 관련된 위험 요소들과 이에 대한 대응 전략을 기술해 주십시오. 위험 관리 계획은 제안서의 신뢰도를 높이는 데 중요합니다.',
        proposal_creation_write: '글쓰기',
        writing_skill_title: '글쓰기 능력 향상',
        writing_skill_title_tooltip:
          '문법적 정확성을 보장하고, 풍부한 어휘를 사용하여 글의 표현력을 강화합니다.',
        writing_skill_description: '개선이 필요한 텍스트를 입력해주세요.',
        writing_skill_write: '개선하기',
        spell_checker_title: '맞춤법 검사기',
        spell_checker_title_tooltip:
          '맞춤법 검사를 원하는 단어나 문장을 입력해주시면 잘못된 맞춤법이 발견되면 올바른 형태로 수정해드립니다.',
        spell_checker_description:
          '맞춤법 검사를 원하는 단어나 문장을 입력하세요.',
        vocabular_correction_title: '어휘력 교정기',
        vocabular_corection_title_tooltip:
          '입력한 단어를 더 정교하고 고급스러운 어휘 5가지로 변환해드립니다.',
        vocabular_correction_description: '단어를 입력해주세요.',
        vacabular_correction_placeholder: 'ex) 없애다',
        dialect_conversion_title: '지역별 사투리 변환기',
        dialect_conversion_title_tooltip: '지역별 사투리를 변환해드립니다.',
        dialect_conversion_location_label:
          '원하는 사투리 지역을 선택해 주세요.',
        dialect_conversion_location_placeholder: '사투리를 선택하세요',
        dialect_conversion_location_option1: '경상도 사투리',
        dialect_conversion_location_option2: '전라도 사투리',
        dialect_conversion_location_option3: '충청도 사투리',
        dialect_conversion_location_option4: '강원도 사투리',
        dialect_conversion_location_option5: '제주도 사투리',
        dialect_conversion_translate_label: '변환하고 싶은 문장을 입력하세요.',
        objective_subjective_title: '객관적/주관적 정보 분류기',
        objective_subjective_title_label:
          '주제에 대한 정보를 객관적인 사실과 주관적인 견해로 구분하여 제공해드립니다.',
        objective_subjective_description: '주제를 입력해주세요',
        objective_subjective_placeholder:
          '주제를 입력하시면 객관적인 사실과 주관적인 견해로 구분하여 제공해드립니다.',
        word_master_title: '영단어 마스터',
        word_master_title_tooltip:
          '입력한 한국어, 영어 단어와 관련된 단어 및 문장들을 나열해드립니다.',
        word_master_description: '단어를 입력해주세요.',
      },
      tutorial: {
        title: '🔮 라이팅젤 사용방법',
        dont_see_day: '오늘 하루 보지 않기',
        dont_see_forever: '앞으로 튜토리얼 보지 않기',
        sequence1: '1. 원하는 글쓰기 종류를 선택합니다.',
        sequence2: '2. 글쓰기에 필요한 요소를 입력/선택합니다.',
        sequence3: '3. 완성된 글을 확인합니다.',
        purpose_save: '💻 아이콘을 눌러 글을 만들고 저장해보세요!',
      },
      success: {
        save_success_title: '성공!',
        save_success_description: '팅젤보관함에 저장됐어요!',
        request_subscribe_success: '구독신청이 완료 되었습니다.',
        inquiry_send_success: '📬 문의사항이 전송 되었습니다.',
        copy_content: '내용을 복사했어요.',
        register_coupon: '쿠폰이 등록되었습니다!',
        inquiry_success: '문의사항이 전송되었습니다 !',
      },
      error: {
        empty_title: '빈 칸이 남아있습니다!',
        empty_description: '빈 칸을 채워주세요!',
        cant_add_anymore_title: '더 추가할 수 없습니다.',
        cant_add_max3_description: '최대 3개까지만 입력할 수 있습니다!',
        not_select_genre_title: '장르가 없습니다!',
        not_select_genre_world_description:
          '세계관을 뽑을 장르를 선택해주세요.',
        not_select_genre_sentence_description:
          '첫문장을 뽑을 장르를 선택해주세요.',
        retry: '다시 시도해주세요!',
        retry_description:
          '결과물에 유해한 내용이 들어갔거나 AI가 문장을 만들지 못했어요.',
        retry_refresh_login:
          '다시 시도해주세요! 문제가 반복되면 새로고침 혹은 재로그인을 해주세요!',
        retry_login_description: '다시 로그인 해주세요!',
        wait_1_minute_description: '1분 후 다시 시도해주세요!',
        lack_string_title: '글자 수가 부족합니다!',
        lack_string_description: '최소 100자를 채워주세요!',
        not_select_idea_title: '선택된 아이디어가 없습니다.',
        not_select_idea_description: '다시 선택해주세요!',
        empty_result_title: '저장할 결과가 없습니다!',
        empty_result_description: '저장할 결과가 나오지 않았습니다',
        chatting_error_title: '채팅 에러 발생',
        cant_add_max5_description: '최대 5개까지만 입력할 수 있습니다!',
        logged_out_title: '로그인이 풀렸습니다.',
        not_enough_token_title: '드로잉젤 토큰 없음',
        not_ebough_token_description: '멤버십 페이지에서 충전해주세요.',
        not_draw_description: '그림이 생성되지 않았습니다.',
        chapter_streaming_error:
          '챕터 컨텐츠를 스트리밍하는 중에 오류가 발생했습니다.',
        empty_content_title: '이미 내용이 비어 있습니다!',
        empty_content_description: '새로운 내용을 생성 중입니다.',
        not_logined_title: '로그인 후 사용 가능합니다.',
        not_logined_description: '로그인을 먼저 해주세요!',
        blocked_popup_title: '팝업이 차단되어 있습니다!',
        blocked_popup_description: '팝업 차단을 풀어주세요!',
        payment_fail_title: '결제 오류',
        payment_info_invalid:
          '결제 정보가 유효하지 않습니다. 재 로그인 후 다시 시도해주세요.',
        payment_processing_fail: '결제 처리 중 문제가 발생했습니다.',
        payment_fail_login_expired_title:
          '로그인이 만료되어 정상처리 되지 않았습니다.',
        payment_fail_login_expired_description:
          '결제 시간,결제 방식을 적어 관리자에게 문의해주세요!',
        imp_problem: 'imp 오류',
        empty_copy_content_title: '복사할 내용이 없습니다',
        empty_copy_content_description: '버튼을 눌러 결과를 만들어주세요!',
        cant_get_user_info: '유저 정보를 가져올 수 없습니다!',
        email_not_certified: '아직 메일이 인증 되지 않았습니다.',
        not_found_user: '삭제 되었거나 등록되지 않은 유저입니다.',
        incorrect_email: '올바른 이메일 형식이 아닙니다.',
        incorrect_password_title: '비밀번호가 맞지 않습니다. ',
        incorrect_password_description:
          '혹시 구글이나 페이스북 계정으로 가입한 계정일까요?',
        email_already_use: '이미 누군가 쓰고 있는 이메일 입니다.',
        incomplete_empty_form: '빈 칸 입니다.',
        incomplete_coupon: '쿠폰 번호가 입력되지 않았습니다!',
        cant_get_payment_log: '최근 결제 내역을 가져오지 못했습니다.',
        streaming_problem_title: '스트리밍 오류',
        streaming_problem_description: '스트리밍 중 문제가 발생했습니다.',
        not_found_user_title: '사용자를 찾을 수 없음',
        not_found_user_description: '존재하지 않는 사용자 입니다.',
      },
      tingel: {
        title_1: '라이팅젤 설문조사 진행중 !',
        title_2: '완료시 이용권 지급',
        link: '바로가기',
      },
    },
  },
};

export default ko;
