import { Button, useToast } from '@chakra-ui/react';
import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import 'dayjs/locale/ko'; //한국어
import { useRecoilState } from 'recoil';
import {
  groupPaymentFormErrorState,
  groupPaymentFormState,
} from 'Config/recoil';
import { prices } from 'Config/paymentInfomation';

dayjs.locale('ko');

const GroupPayQuotationButton = () => {
  const [groupPaymentForm, setGroupPaymentForm] = useRecoilState(
    groupPaymentFormState,
  );
  const [groupPaymentFormError, setGroupPaymentFormError] = useRecoilState(
    groupPaymentFormErrorState,
  );
  const toast = useToast();

  const createGroupPayQuation = async () => {
    try {
      // 유효성 검증
      const emailRegExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      const phonePattern =
        /^(01[016789]\d{7,8})|(02\d{7,8})|(0[3-9]{1}[0-9]{1}\d{7,8})$/;

      const validationResult = {
        institutionName: groupPaymentForm.institutionName.length <= 1,
        managerName: groupPaymentForm.managerName.length <= 1,
        phone: !phonePattern.test(groupPaymentForm.phone),
        departmentName: false,
        managerEmail: !emailRegExp.test(groupPaymentForm.managerEmail),
        membershipOption: false,
        finalPayEmails: groupPaymentForm.finalPayEmails.length === 0,
      };

      if (groupPaymentForm.finalPayEmails.length === 0) {
        toast({
          position: 'top-right',
          title: '검증된 이메일 없음',
          description: `이메일을 검증해주세요.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      } else if (groupPaymentForm.finalPayEmails.length < 5) {
        toast({
          position: 'top-right',
          title: '최소 계정 수 부족',
          description: `이메일은 5개 이상부터만 단체 결제 가능합니다.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // 유효성 체크 통과했는지 검증
      const errorArray = [...new Set(Object.values(validationResult))].filter(
        (el) => el,
      );

      if (errorArray.length !== 0) {
        setGroupPaymentFormError(validationResult);
        toast({
          position: 'top-right',
          title: '유효성 검증 실패',
          description: `올바른 양식을 입력해주세요.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // 기존 견적서 양식 불러오기
      // 견적서에 현재날짜(견적서 생성일 기준) 기관명, 상품옵션, 결제금액, 계정 추가하기
      // 견적서 다운로드
      // 1. 고정된 파일 불러오기 (public 폴더에 있다고 가정)
      const response = await fetch(
        'https://tingel-image.s3.ap-northeast-2.amazonaws.com/1727416966359quotation.xlsx',
      );
      const arrayBuffer = await response.arrayBuffer();

      // 2. ExcelJS를 사용하여 워크북 생성
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      // 3. 워크시트 가져오기 (첫 번째 시트 사용)
      const worksheet = workbook.getWorksheet(1);

      // 4. 엑셀 파일 수정

      // A3 날짜 (0000년 00월 00일 0요일)
      worksheet.getCell('A3').value = dayjs().format('YYYY년 MM월 DD일 dddd');
      // A7 기관명 (기관명) 귀중 아래와 같이 견적합니다.
      worksheet.getCell(
        'A7',
      ).value = `${groupPaymentForm.institutionName} 귀중 아래와 같이 견적합니다.`;
      // H11 (부서)/(전화번호)
      worksheet.getCell('H11').value = `${
        groupPaymentForm.departmentName
      } / ${groupPaymentForm.phone.slice(0, 3)}-${groupPaymentForm.phone.slice(
        3,
        7,
      )}-${groupPaymentForm.phone.slice(7, 11)}`;
      // G11 기관명 (기관명)
      worksheet.getCell('G11').value = `${groupPaymentForm.institutionName}`;
      // I11 담당자 성함 (담당자 성함)
      worksheet.getCell('I11').value = `${groupPaymentForm.managerName}`;
      // F12 (일금 ㅇㅇ만 원정)
      worksheet.getCell('F12').value = `일금 ${
        groupPaymentForm.finalPayEmails.length *
        prices[groupPaymentForm.membershipOption] *
        0.8
      } 원정`;

      // GHI12 110000(부가세포함)
      worksheet.getCell('G12').value = `${
        groupPaymentForm.finalPayEmails.length *
        prices[groupPaymentForm.membershipOption] *
        0.8
      } (부가세 포함)`;
      // AEF14 인공지능 글쓰기 라이팅젤 학습 프로그램 (ㅇㅇ ㅇㅇㅇ) 이용권
      worksheet.getCell(
        'A14',
      ).value = `인공지능 글쓰기 라이팅젤 학습 프로그램 ${groupPaymentForm.membershipOption} 이용권`;
      // G14 단가
      worksheet.getCell('G14').value =
        groupPaymentForm.finalPayEmails.length *
        prices[groupPaymentForm.membershipOption] *
        0.8;
      // H14 개수
      worksheet.getCell('H14').value = groupPaymentForm.finalPayEmails.length;
      // I14 공급가
      worksheet.getCell('I14').value = Math.round(
        ((groupPaymentForm.finalPayEmails.length *
          prices[groupPaymentForm.membershipOption]) /
          1.1) *
          0.8,
      );
      // I15 부가세
      worksheet.getCell('I15').value = Math.round(
        ((groupPaymentForm.finalPayEmails.length *
          prices[groupPaymentForm.membershipOption]) /
          11) *
          0.8,
      );
      // I17  합계
      worksheet.getCell('I17').value =
        groupPaymentForm.finalPayEmails.length *
        prices[groupPaymentForm.membershipOption] *
        0.8;

      // 수정된 파일 다운로드
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, '라이팅젤_단체결제_견적서.xlsx');
    } catch (err) {
      console.error('Error modifying Excel file:', err);
    }
  };
  return (
    <div>
      <Button marginRight="15px" size="lg" onClick={createGroupPayQuation}>
        견적서 생성
      </Button>
    </div>
  );
};

export default GroupPayQuotationButton;
