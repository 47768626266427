import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CardBody, CardHeader } from 'Components/Common/Card';
import { ProfileState } from 'Config/recoil';
import dayjs from 'dayjs';
import { checkedToken } from 'Hook/CheckedToken';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import CustomPaymentSuccessModal from './CustomPaymentSuccessModal';

const CustomPayment = () => {
  const toast = useToast();
  const [profile, setProfile] = useRecoilState(ProfileState);
  const [customPaymentInfo, setCustomPaymentInfo] = useState({
    price: '',
    name: '',
    email: '',
    phone: '',
  });

  const [customPaymentInfoError, setCustomPaymentInfoError] = useState({
    price: false,
    name: false,
    email: false,
    phone: false,
  });

  const [customPaymentSuccessModalOpen, setCustomPaymentSuccessModalOpen] =
    useState(false);

  const { IMP } = window;

  const callCustomPayment = async () => {
    try {
      // 로그인 상태 검증
      const token = localStorage.getItem('token');
      const valid = await checkedToken(token);
      if (!valid) {
        toast({
          position: 'top-right',
          title: t('error.logged_out_title'),
          description: `${t('error.retry_login_description')}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // 유효성 검사
      const emailRegExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      const phonePattern =
        /^(01[016789]\d{7,8})|(02\d{7,8})|(0[3-9]{1}[0-9]{1}\d{7,8})$/;

      const validationResult = {
        price:
          parseInt(customPaymentInfo.price) === 0 ||
          isNaN(parseInt(customPaymentInfo.price)),
        name: customPaymentInfo.name.length < 2,
        email: !emailRegExp.test(customPaymentInfo.email),
        phone: !phonePattern.test(customPaymentInfo.phone),
      };

      // 유효성 체크 통과했는지 검증
      const errorArray = [...new Set(Object.values(validationResult))].filter(
        (el) => el,
      );

      if (errorArray.length !== 0) {
        setCustomPaymentInfoError(validationResult);
        toast({
          position: 'top-right',
          title: '유효성 검증 실패',
          description: `올바른 양식을 입력해주세요.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // 결제 프로세스

      const now = new Date();
      const moidNum = dayjs(now).unix();
      const uid = profile.user.user_uid;

      const merchant_uid = `${uid}_${moidNum}`;

      IMP.init('imp33624147');
      IMP.request_pay(
        {
          pg: 'html5_inicis',
          pay_method: 'card',
          merchant_uid, // 상점에서 관리하는 주문 번호를 전달
          name: `사용자 지정 결제`,
          amount: parseInt(customPaymentInfo.price),
          buyer_email: customPaymentInfo.email,
          buyer_name: customPaymentInfo.name,
          buyer_tel: customPaymentInfo.phone,
          // m_redirect_url: `${process.env.REACT_APP_FRONT_URL}/cardpay`,
        },
        async (rsp) => {
          if (!rsp.success) {
            toast({
              position: 'top-right',
              title: t('error.imp_problem'),
              description: `${rsp.error_msg}`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }

          if (rsp.success) {
            setCustomPaymentSuccessModalOpen(true);
          }
        },
      );
    } catch (error) {
      console.error(error);
      const errorStatus = error.response.status;
      const errorResMessage = error.response.data.message;
      toast({
        position: 'top-right',
        title: 'Fail',
        description: `[${errorStatus}] ${errorResMessage}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {' '}
      <Box w={'100%'} h={'100dvh'}>
        <Flex
          direction={'column'}
          justify={'center'}
          maxW="400px"
          p="30px 20px"
          margin={'150px auto'}
          className="MembershipCard"
        >
          <CardHeader>
            <Heading
              as="h3"
              size="sm"
              pb="15px"
              borderBottom={'1px solid #ededed'}
              display={'flex'}
            >
              결제할 금액을 직접 입력하세요
            </Heading>
            <Text fontSize={'2xl'} fontWeight="600" pt="15px">
              사용자 지정 결제
            </Text>
          </CardHeader>
          <CardBody>
            <Box>
              <Flex lineHeight={'30px'} w="100%">
                <label
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: '10px',
                    width: '90px',
                  }}
                >
                  금액
                </label>
                <Input
                  type="number"
                  value={parseInt(customPaymentInfo.price)}
                  onChange={(e) => {
                    setCustomPaymentInfoError({
                      ...customPaymentInfoError,
                      price:
                        parseInt(e.currentTarget.value) === 0 ||
                        isNaN(parseInt(e.currentTarget.value)),
                    });
                    setCustomPaymentInfo({
                      ...customPaymentInfo,
                      price: parseInt(e.currentTarget.value),
                    });
                  }}
                />
              </Flex>
              {!!customPaymentInfoError.price && (
                <Text color={'red'}>0원은 결제 불가능합니다.</Text>
              )}

              <Flex lineHeight={'30px'} w="100%" marginTop={'20px'}>
                <label
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: '10px',
                    width: '90px',
                  }}
                >
                  이름
                </label>

                <Input
                  value={customPaymentInfo.name}
                  onChange={(e) => {
                    setCustomPaymentInfoError({
                      ...customPaymentInfoError,
                      name: e.currentTarget.value.length < 2,
                    });
                    setCustomPaymentInfo({
                      ...customPaymentInfo,
                      name: e.currentTarget.value,
                    });
                  }}
                />
              </Flex>
              {customPaymentInfoError.name && (
                <Text color="red" paddingLeft={'75px'}>
                  이름은 두 글자 이상이어야 합니다.
                </Text>
              )}

              <Flex lineHeight={'30px'} marginTop={'20px'}>
                <label
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: '10px',
                    width: '90px',
                  }}
                >
                  Email
                </label>
                <Input
                  type="email"
                  value={customPaymentInfo.email}
                  onChange={(e) => {
                    const emailRegExp =
                      /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                    setCustomPaymentInfoError({
                      ...customPaymentInfoError,
                      email: !emailRegExp.test(e.currentTarget.value),
                    });
                    setCustomPaymentInfo({
                      ...customPaymentInfo,
                      email: e.currentTarget.value,
                    });
                  }}
                />
              </Flex>
              {customPaymentInfoError.email && (
                <Text color="red" paddingLeft={'75px'}>
                  이메일 형식이 적절하지 않습니다.
                </Text>
              )}
              <Flex
                lineHeight={'30px'}
                alignItems={'center'}
                w={'100%'}
                marginTop={'20px'}
              >
                <label
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: '10px',
                    width: '90px',
                  }}
                >
                  전화번호
                </label>
                <Input
                  type="tel"
                  value={customPaymentInfo.phone}
                  onChange={(e) => {
                    const phonePattern =
                      /^(01[016789]\d{7,8})|(02\d{7,8})|(0[3-9]{1}[0-9]{1}\d{7,8})$/;
                    setCustomPaymentInfoError({
                      ...customPaymentInfoError,
                      phone: !phonePattern.test(e.currentTarget.value),
                    });
                    // 숫자 외의 문자 제거
                    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                    setCustomPaymentInfo({
                      ...customPaymentInfo,
                      phone: onlyNums,
                    });
                  }}
                  placeholder="- 없이 입력하세요"
                  maxLength={11}
                />
              </Flex>
              {customPaymentInfoError.phone && (
                <Text color="red" paddingLeft={'75px'}>
                  전화번호 형식이 적절하지 않습니다.
                </Text>
              )}
            </Box>

            <Button w="100%" colorScheme="purple" onClick={callCustomPayment}>
              결제하기
            </Button>
          </CardBody>
        </Flex>
      </Box>
      <CustomPaymentSuccessModal
        onClose={() => {
          setCustomPaymentSuccessModalOpen(false);
        }}
        isOpen={customPaymentSuccessModalOpen}
      />
    </>
  );
};

export default CustomPayment;
