import { Box, Button, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import FreeMembershipCard from './FreeMembershipCard';
import { CardUI, CardBody, CardHeader } from 'Components/Common/Card';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { t } from 'i18next';
import { getLanguage } from 'language/i18n';
import { useNavigate } from 'react-router-dom';

const GroupMembership = () => {
  const navigate = useNavigate();
  return (
    <Flex w={'100%'} justify="center" maxW="800px" m="0 auto" spacing="40px">
      <CardUI>
        <CardHeader>
          <Heading
            as="h3"
            size="sm"
            pb="15px"
            borderBottom={'1px solid #ededed'}
          >
            단체 결제
          </Heading>
          <Text fontSize={'2xl'} fontWeight="600" pt="15px">
            견적별 가격 상이
          </Text>
        </CardHeader>
        <CardBody>
          <Box lineHeight={'30px'}>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              선택 옵션에 따른 할인된 가격 제시
            </Flex>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              견적서 제공 및 결제
            </Flex>
          </Box>
          <Button
            w="100%"
            colorScheme="purple"
            onClick={() => {
              navigate('/membership/group');
            }}
          >
            결제 페이지 이동하기
          </Button>
        </CardBody>
      </CardUI>
    </Flex>
  );
};

export default GroupMembership;
