import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const CustomPaymentSuccessModal = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} size={'md'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>결제 성공 !</ModalHeader>
        <ModalBody>
          <Text textAlign={'center'}>
            결제가 완료되었습니다. 관리자에게 문의하세요.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>확인</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomPaymentSuccessModal;
