import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'Components/Common/NotFound';
// import AppHome from 'Components/App/AppHome';
// import FirstSentence from 'Components/App/FirstSentence';
import ServiceLayout from 'Components/Layout/ServiceLayout';
import MainLayout from 'Components/Layout/MainLayout';
import Login from 'Components/Home/Login';
import SignUp from 'Components/Home/SignUp';
import SignUpEmail from 'Components/Home/SignUp/SignUpEmail';
// import WritingMaterial from 'Components/App/WritingMaterial';
// import Fantasy from 'Components/App/Fantasy';
// import Lyrics from 'Components/App/Lyrics';
// import Diary from 'Components/App/Diary';
// import Discussion from 'Components/App/Discussion';
// import BusinessIdea from 'Components/App/BusinessIdea';
// import StorySrc from 'Components/App/StorySrc';
// import ProductIntro from 'Components/App/ProductIntro';
// import ProductReview from 'Components/App/ProductReview';
// import Coverletter from 'Components/App/Coverletter';
// import Resume from 'Components/App/Resume';
// import WritingEmail from 'Components/App/WritingEmail';
// import MBTILoveletter from 'Components/App/MBTILoveletter';
// import Ideas from 'Components/App/Blogs/Ideas';
// import Outline from 'Components/App/Blogs/Outline';
// import Intro from 'Components/App/Blogs/Intro';
// import Follow from 'Components/App/Blogs/Follow';
// import NovelIntro from 'Components/App/Novel/Intro';
// import NovelFollow from 'Components/App/Novel/Follow';
// import FairyIntro from 'Components/App/Fairy/Intro';
// import FairyFollow from 'Components/App/Fairy/Follow';
// import NewsletterEdit from 'Components/App/NewsletterEdit';
// import NewsletterEdit1 from 'Components/App/NewsletterEdit/LetterEdit1';
// import NewsletterEdit2 from 'Components/App/NewsletterEdit/LetterEdit2';
// import NewsletterEdit3 from 'Components/App/NewsletterEdit/LetterEdit3';
import Notice from 'Components/Home/Customer/Notice';
import Faq from 'Components/Home/Customer/Faq';
import Contact from 'Components/Home/Customer/Contact';
import EbookFantasy from 'Components/Home/ServiceInfo/TingelEbook/EbookFantasy';
import EbookFirstsentece from 'Components/Home/ServiceInfo/TingelEbook/EbookFirstsentence';
import SubsLetter from 'Components/Home/SubsLetter';

import Membership from 'Components/Home/Membership';
// import MBTILetterDetail from 'Components/Home/ServiceInfo/ServiceDetail/MBTILetterDetail';
import SignLayout from 'Components/Layout/SignLayout';
import Welcome from 'Components/Common/Welcome';
import Authpage from 'Hook/Authpage';
// import Mypage from 'Components/Home/Mypage';
// import TingelBox from 'Components/Home/TingelBox';
import useScrollToTop from 'Hook/useScrollTop';
// import FirstSenteceDetail from 'Components/Home/ServiceInfo/ServiceDetail/FirstSentenceDetail';
// import MaterialDetail from 'Components/Home/ServiceInfo/ServiceDetail/MaterialDetail';
// import FantasyDetail from 'Components/Home/ServiceInfo/ServiceDetail/FantasyDetail';
// import LyricsDetail from 'Components/Home/ServiceInfo/ServiceDetail/LyricsDetail';
// import DiaryDetail from 'Components/Home/ServiceInfo/ServiceDetail/DiaryDetail';
// import BusinessDetail from 'Components/Home/ServiceInfo/ServiceDetail/BusinessDetail';
// import DiscussionDetail from 'Components/Home/ServiceInfo/ServiceDetail/DiscussionDetail';
// import StorySrcDetail from 'Components/Home/ServiceInfo/ServiceDetail/StorySrcDetail';
// import CoverLetterDetail from 'Components/Home/ServiceInfo/ServiceDetail/CoverLetterDetail';
// import ResumeDetail from 'Components/Home/ServiceInfo/ServiceDetail/ResumeDetail';
// import EmailDetail from 'Components/Home/ServiceInfo/ServiceDetail/EmailDetail';
// import ProductDetail from 'Components/Home/ServiceInfo/ServiceDetail/ProductDetail';
// import BlogDetail from 'Components/Home/ServiceInfo/ServiceDetail/BlogDetail';
// import NovelDetail from 'Components/Home/ServiceInfo/ServiceDetail/NovelDetail';
// import FairyDetail from 'Components/Home/ServiceInfo/ServiceDetail/FairyDetail';
// import NextSentenceDetail from 'Components/Home/ServiceInfo/ServiceDetail/NextSentenceDetail';
// import LetterEditDetail from 'Components/Home/ServiceInfo/ServiceDetail/LetterEditDetail';
import Paydone from 'Components/Common/PayDone';
import CardPayRedirect from 'Hook/CardPayRedirect';
import Failpay from 'Components/Common/FailPay';
import KakaoPayRedirect from 'Hook/KakaoPayRedirect';
// import NextSentence from 'Components/App/NextSentence';
// import Drawinggel from 'Components/App/Drawinggel';
import Loading from 'Components/Common/Loading';
// import DrawPdfDownload from 'Components/App/Drawinggel/DrawPdfDownload';
// import Chattingel from 'Components/App/Chattingel';
// import EmailSummary from 'Components/App/EmailSummary';
// import WritingSkill from 'Components/App/WritingSkill';
// import MeetingSummary from 'Components/App/MeetingSummary';
// import MonthlyReport from 'Components/App/MonthlyReport';
// import HolidayAnnouncements from 'Components/App/HolidayAnnouncements';
// import FormalDocumentation from 'Components/App/FormalDocumentation';
// import ProposalCreation from 'Components/App/ProposalCreation';
// import Topic from 'Components/App/StoryBook/Topic';
// import Chapter from 'Components/App/StoryBook/Chapter';
// import Story from 'Components/App/StoryBook/Story';
// import Title from 'Components/App/StoryBook/Title';
// import Publication from 'Components/App/StoryBook/Publication';
// import Proofread from 'Components/App/StoryBook/Proofread';
// import DialectConversion from 'Components/App/DialectConversion';
// import ObjectiveSubjective from 'Components/App/ObjectiveSubjective';
// import SpellChecker from 'Components/App/SpellChecker';
// import VocabularCorrection from 'Components/App/VocabularCorrection';
// import StoryBook_use from 'Components/App/StoryBook/StoryBook_use';
// import WordMaster from 'Components/App/ForeignLanguage/WordMaster';
import PayPending from 'Components/Common/PayPending';
import ResetPassword from 'Components/Home/ResetPassword';
import GroupPay from 'Components/Home/Membership/Group/GroupPay';
import CustomPayment from 'Components/Home/Membership/Custom/CustomPayment';

const AppHome = lazy(() => import('Components/App/AppHome'));
const Mypage = lazy(() => import('Components/Home/Mypage'));
const TingelBox = lazy(() => import('Components/Home/TingelBox'));
const WritingSkill = lazy(() => import('Components/App/WritingSkill'));
const MBTILoveletter = lazy(() => import('Components/App/MBTILoveletter'));
const FirstSentence = lazy(() => import('Components/App/FirstSentence'));
const NextSentence = lazy(() => import('Components/App/NextSentence'));
const WritingMaterial = lazy(() => import('Components/App/WritingMaterial'));
const Fantasy = lazy(() => import('Components/App/Fantasy'));
const Lyrics = lazy(() => import('Components/App/Lyrics'));
const Diary = lazy(() => import('Components/App/Diary'));
const Discussion = lazy(() => import('Components/App/Discussion'));
const BusinessIdea = lazy(() => import('Components/App/BusinessIdea'));
const StorySrc = lazy(() => import('Components/App/StorySrc'));
const Coverletter = lazy(() => import('Components/App/Coverletter'));
const Resume = lazy(() => import('Components/App/Resume'));
const WritingEmail = lazy(() => import('Components/App/WritingEmail'));
const EmailSummary = lazy(() => import('Components/App/EmailSummary'));
const MeetingSummary = lazy(() => import('Components/App/MeetingSummary'));
const ProductIntro = lazy(() => import('Components/App/ProductIntro'));
const ProductReview = lazy(() => import('Components/App/ProductReview'));
const WordMaster = lazy(() =>
  import('Components/App/ForeignLanguage/WordMaster'),
);
const Topic = lazy(() => import('Components/App/StoryBook/Topic'));
const Chapter = lazy(() => import('Components/App/StoryBook/Chapter'));
const Story = lazy(() => import('Components/App/StoryBook/Story'));
const Proofread = lazy(() => import('Components/App/StoryBook/Proofread'));
const Title = lazy(() => import('Components/App/StoryBook/Title'));
const Publication = lazy(() => import('Components/App/StoryBook/Publication'));
const StoryBook_use = lazy(() =>
  import('Components/App/StoryBook/StoryBook_use'),
);
const MonthlyReport = lazy(() => import('Components/App/MonthlyReport'));
const HolidayAnnouncements = lazy(() =>
  import('Components/App/HolidayAnnouncements'),
);
const FormalDocumentation = lazy(() =>
  import('Components/App/FormalDocumentation'),
);
const ProposalCreation = lazy(() => import('Components/App/ProposalCreation'));
const Ideas = lazy(() => import('Components/App/Blogs/Ideas'));
const Outline = lazy(() => import('Components/App/Blogs/Outline'));
const Intro = lazy(() => import('Components/App/Blogs/Intro'));
const Follow = lazy(() => import('Components/App/Blogs/Follow'));
const NovelIntro = lazy(() => import('Components/App/Novel/Intro'));
const NovelFollow = lazy(() => import('Components/App/Novel/Follow'));
const FairyIntro = lazy(() => import('Components/App/Fairy/Intro'));
const FairyFollow = lazy(() => import('Components/App/Fairy/Follow'));
const NewsletterEdit = lazy(() => import('Components/App/NewsletterEdit'));
const NewsletterEdit1 = lazy(() =>
  import('Components/App/NewsletterEdit/LetterEdit1'),
);
const NewsletterEdit2 = lazy(() =>
  import('Components/App/NewsletterEdit/LetterEdit2'),
);
const NewsletterEdit3 = lazy(() =>
  import('Components/App/NewsletterEdit/LetterEdit3'),
);
const DialectConversion = lazy(() =>
  import('Components/App/DialectConversion'),
);
const ObjectiveSubjective = lazy(() =>
  import('Components/App/ObjectiveSubjective'),
);
const SpellChecker = lazy(() => import('Components/App/SpellChecker'));
const VocabularCorrection = lazy(() =>
  import('Components/App/VocabularCorrection'),
);
const Chattingel = lazy(() => import('Components/App/Chattingel'));
const Drawinggel = lazy(() => import('Components/App/Drawinggel'));
const DrawPdfDownload = lazy(() =>
  import('Components/App/Drawinggel/DrawPdfDownload'),
);

const Home = lazy(() => import('Components/Home'));

const AboutUs = lazy(() => import('Components/Home/AboutUs'));

const ServiceDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail'),
);

const MBTILetterDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/MBTILetterDetail'),
);
const FirstSenteceDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/FirstSentenceDetail'),
);
const MaterialDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/MaterialDetail'),
);
const FantasyDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/FantasyDetail'),
);
const LyricsDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/LyricsDetail'),
);
const DiaryDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/DiaryDetail'),
);
const DiscussionDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/DiscussionDetail'),
);
const BusinessDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/BusinessDetail'),
);
const StorySrcDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/StorySrcDetail'),
);
const CoverLetterDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/CoverLetterDetail'),
);
const ResumeDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/ResumeDetail'),
);
const EmailDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/EmailDetail'),
);
const ProductDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/ProductDetail'),
);
const BlogDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/BlogDetail'),
);
const NovelDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/NovelDetail'),
);
const FairyDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/FairyDetail'),
);
const NextSentenceDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/NextSentenceDetail'),
);
const LetterEditDetail = lazy(() =>
  import('Components/Home/ServiceInfo/ServiceDetail/LetterEditDetail'),
);
const TingelEbook = lazy(() =>
  import('Components/Home/ServiceInfo/TingelEbook'),
);
const Videogel = lazy(() => import('Components/App/Videogel'));
const YoutubeSummary = lazy(() =>
  import('Components/App/YoutubeSummary/YoutubeSummary'),
);

function App() {
  useScrollToTop();

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="about_us" element={<AboutUs />} />
          <Route path="notice" element={<Notice />} />
          <Route path="membership" element={<Membership />} />
          <Route path="membership/group" element={<GroupPay />} />
          <Route path="membership/custom" element={<CustomPayment />} />
          <Route path="faq" element={<Faq />} />
          <Route path="contact" element={<Contact />} />

          <Route path="ebook" element={<TingelEbook />} />
          <Route path="ebook/fantasy" element={<EbookFantasy />} />
          <Route path="ebook/firstsentence" element={<EbookFirstsentece />} />
          <Route path="newsletter" element={<SubsLetter />} />

          {/* 서비스 디테일 */}
          <Route path="detail" element={<ServiceDetail />} />
          <Route path="detail/mbti_loveletter" element={<MBTILetterDetail />} />
          <Route path="detail/firstsentence" element={<FirstSenteceDetail />} />
          <Route path="detail/material" element={<MaterialDetail />} />
          <Route path="detail/fantasy" element={<FantasyDetail />} />
          <Route path="detail/lyrics" element={<LyricsDetail />} />
          <Route path="detail/diary" element={<DiaryDetail />} />
          <Route path="detail/discussion" element={<DiscussionDetail />} />
          <Route path="detail/business" element={<BusinessDetail />} />
          <Route path="detail/storysrc" element={<StorySrcDetail />} />
          <Route path="detail/coverletter" element={<CoverLetterDetail />} />
          <Route path="detail/resume" element={<ResumeDetail />} />
          <Route path="detail/email" element={<EmailDetail />} />
          <Route path="detail/product_writing" element={<ProductDetail />} />

          <Route path="detail/blog" element={<BlogDetail />} />
          <Route path="detail/novel" element={<NovelDetail />} />
          <Route path="detail/fairy" element={<FairyDetail />} />
          <Route path="detail/nextsentence" element={<NextSentenceDetail />} />
          <Route path="detail/letter_edit" element={<LetterEditDetail />} />
        </Route>

        <Route path="/paydone" element={<Paydone />} />
        <Route path="/cardpay" element={<CardPayRedirect />} />
        <Route path="/pay_redirect" element={<KakaoPayRedirect />} />
        <Route path="/pay_pending" element={<PayPending />} />
        <Route path="/failpay" element={<Failpay />} />

        <Route path="/sign" element={<SignLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="signup_email" element={<SignUpEmail />} />
          <Route path="reset_password" element={<ResetPassword />} />
        </Route>
        <Route path="/sign/welcome" element={<Welcome />} />
        <Route path="/oauth" element={<Authpage />} />
        <Route path="/service" element={<ServiceLayout />}>
          <Route index element={<AppHome />} />

          <Route path="mypage" element={<Mypage />} />
          <Route path="tingelbox" element={<TingelBox />} />

          <Route path="writingSkill" element={<WritingSkill />} />
          <Route path="mbti_loveletter" element={<MBTILoveletter />} />
          <Route path="firstsentence" element={<FirstSentence />} />
          <Route path="nextsentence" element={<NextSentence />} />
          <Route path="material" element={<WritingMaterial />} />
          <Route path="fantasy" element={<Fantasy />} />
          <Route path="lyrics" element={<Lyrics />} />
          <Route path="diary" element={<Diary />} />
          <Route path="discussion" element={<Discussion />} />
          <Route path="business" element={<BusinessIdea />} />
          <Route path="storysrc" element={<StorySrc />} />
          <Route path="coverletter" element={<Coverletter />} />
          <Route path="resume" element={<Resume />} />
          <Route path="email" element={<WritingEmail />} />
          <Route path="EmailSummary" element={<EmailSummary />} />
          <Route path="MeetingSummary" element={<MeetingSummary />} />
          <Route path="product_intro" element={<ProductIntro />} />
          <Route path="product_review" element={<ProductReview />} />

          <Route path="wordMaster" element={<WordMaster />} />

          <Route path="storybook/topic" element={<Topic />} />
          <Route path="storybook/chapter" element={<Chapter />} />
          <Route path="storybook/story" element={<Story />} />
          <Route path="storybook/proofread" element={<Proofread />} />
          <Route path="storybook/title" element={<Title />} />
          <Route path="storybook/publication" element={<Publication />} />
          <Route path="storybook" element={<StoryBook_use />} />

          <Route path="monthlyReport" element={<MonthlyReport />} />
          <Route
            path="holidayAnnouncements"
            element={<HolidayAnnouncements />}
          />
          <Route path="formalDocumentation" element={<FormalDocumentation />} />
          <Route path="proposalCreation" element={<ProposalCreation />} />

          <Route path="blog/idea" element={<Ideas />} />
          <Route path="blog/outline" element={<Outline />} />
          <Route path="blog/intro" element={<Intro />} />
          <Route path="blog/follow" element={<Follow />} />

          <Route path="novel/intro" element={<NovelIntro />} />
          <Route path="novel/follow" element={<NovelFollow />} />

          <Route path="fairy/intro" element={<FairyIntro />} />
          <Route path="fairy/follow" element={<FairyFollow />} />

          <Route path="letter_edit" element={<NewsletterEdit />} />
          <Route path="letter_edit/1" element={<NewsletterEdit1 />} />
          <Route path="letter_edit/2" element={<NewsletterEdit2 />} />
          <Route path="letter_edit/3" element={<NewsletterEdit3 />} />

          <Route path="dialectConversion" element={<DialectConversion />} />
          <Route path="objectiveSubjective" element={<ObjectiveSubjective />} />
          <Route path="spellChecker" element={<SpellChecker />} />
          <Route path="vocabularCorrection" element={<VocabularCorrection />} />

          <Route path="chatting_gel" element={<Chattingel />} />

          <Route path="drawing_gel" element={<Drawinggel />} />
          <Route
            path="drawing_gel/pdf_download"
            element={<DrawPdfDownload />}
          />
          <Route path="video_gel" element={<Videogel />} />

          <Route path="youtube_summary" element={<YoutubeSummary />} />
        </Route>

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
