import React, { useEffect } from 'react';
import styled from 'styled-components';
import ServiceHeader from 'Components/Layout/ServiceHeader';
import ServiceSider from 'Components/Layout/ServiceSider';
import { Box, Flex, useMediaQuery, useToast } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getAuth } from '@firebase/auth';
import { authService } from 'Config/Firebase';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ProfileState } from 'Config/recoil';
import { getMyProfile, postKakaoLogIn, postLogIn } from 'apis/user';
import { SignOut } from 'Hook/SignOut';

const Content = styled(Box)`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;

const ServiceLayout = () => {
  const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
  const { Kakao } = window;
  const isLogin = localStorage.getItem('isLogin');
  const token = localStorage.getItem('token');
  const User = JSON.parse(localStorage.getItem('User'));
  const toast = useToast();
  const navigate = useNavigate();
  const auth = getAuth();

  const profile = useRecoilValue(ProfileState);
  const setProfile = useSetRecoilState(ProfileState);

  const getProfile = async (token) => {
    const response = await getMyProfile().catch((error) => {
      const errorStatus = error?.response?.status;
      const errorResMessage = error?.response?.data?.message;
      if (errorStatus === 412) {
        //토큰 만료 됐으면
        SignOut();
      } else {
        toast({
          position: 'top-right',
          title: 'Fail',
          description: `[${errorStatus}] ${errorResMessage}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    const dataUser = response?.data?.data?.user;
    const dataMembership = response?.data?.data?.membership;

    setProfile({
      ...profile,
      user: dataUser,
      membership: dataMembership,
    });

    const User = {
      email: dataUser?.email,
      create: dataUser?.create_at,
      provider: dataUser?.provider,
      userName: dataUser?.name,
      userImage: dataUser?.picture,
    };
    localStorage.setItem('User', JSON.stringify(User));
  };

  useEffect(() => {
    if (isLogin) {
      getProfile(token);
    }

    if (!isLogin) navigate('/');
  }, [token, isLogin]);

  return (
    <>
      <Flex>
        {isLargerThan960 && <ServiceSider />}
        <Content>
          <ServiceHeader User={User} />
          <Outlet />
        </Content>
      </Flex>
    </>
  );
};

export default ServiceLayout;
