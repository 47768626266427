import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import EmailInputsBox from './EmailInputsBox';
import shortid from 'shortid';

import FinalGroupPayInfo from './FinalGroupPayInfo';
import { useRecoilState } from 'recoil';
import {
  groupPaymentFormErrorState,
  groupPaymentFormState,
} from 'Config/recoil';
import axios from 'axios';
import { SERVER_URL } from 'Config/server';

const GroupPay = () => {
  const toast = useToast();
  const [emailInputs, setEmailInputs] = useState([
    {
      id: shortid.generate(),
      email: '',
      isFormatValid: false,
      isNotExist: undefined,
    },
    {
      id: shortid.generate(),
      email: '',
      isFormatValid: false,
      isNotExist: undefined,
    },
    {
      id: shortid.generate(),
      email: '',
      isFormatValid: false,
      isNotExist: undefined,
    },
    {
      id: shortid.generate(),
      email: '',
      isFormatValid: false,
      isNotExist: undefined,
    },
    {
      id: shortid.generate(),
      email: '',
      isFormatValid: false,
      isNotExist: undefined,
    },
  ]);

  const [groupPaymentForm, setGroupPaymentForm] = useRecoilState(
    groupPaymentFormState,
  );
  const [groupPaymentFormError, setGroupPaymentFormError] = useRecoilState(
    groupPaymentFormErrorState,
  );

  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      <Heading marginBottom="30px">단체 결제</Heading>
      <Box>
        <Flex alignItems={'center'}>
          <label
            style={{ whiteSpace: 'nowrap', marginRight: '15px', width: '60px' }}
          >
            기관명
          </label>
          <Input
            w={{ base: '200px', md: '300px' }}
            value={groupPaymentForm.institutionName}
            onChange={(e) => {
              // 유효성 검증
              setGroupPaymentFormError({
                ...groupPaymentFormError,
                institutionName: e.currentTarget.value.length <= 1,
              });

              setGroupPaymentForm({
                ...groupPaymentForm,
                institutionName: e.currentTarget.value,
              });
            }}
          />
        </Flex>
        {groupPaymentFormError.institutionName && (
          <Text color={'red'} paddingLeft={'75px'}>
            {'기관명은 2글자 이상입니다.'}
          </Text>
        )}

        <Flex alignItems={'center'} marginTop={'20px'}>
          <label
            style={{ whiteSpace: 'nowrap', marginRight: '15px', width: '60px' }}
          >
            담당자
          </label>
          <Input
            w={{ base: '200px', md: '300px' }}
            value={groupPaymentForm.managerName}
            onChange={(e) => {
              setGroupPaymentFormError({
                ...groupPaymentFormError,
                managerName: e.currentTarget.value.length <= 1,
              });

              setGroupPaymentForm({
                ...groupPaymentForm,
                managerName: e.currentTarget.value,
              });
            }}
          />
        </Flex>
        {groupPaymentFormError.managerName && (
          <Text color={'red'} paddingLeft={'75px'}>
            {'담당자명은 2글자 이상입니다.'}
          </Text>
        )}

        <Flex alignItems={'center'} marginTop={'20px'}>
          <label
            style={{ whiteSpace: 'nowrap', marginRight: '15px', width: '60px' }}
          >
            부서명
          </label>
          <Input
            w={{ base: '200px', md: '300px' }}
            value={groupPaymentForm.departmentName}
            onChange={(e) => {
              setGroupPaymentForm({
                ...groupPaymentForm,
                departmentName: e.currentTarget.value,
              });
            }}
          />
        </Flex>

        <Flex alignItems={'center'} marginTop={'20px'}>
          <label
            style={{ whiteSpace: 'nowrap', marginRight: '15px', width: '60px' }}
          >
            Email
          </label>
          <Input
            type="email"
            placeholder="담당자 이메일"
            w={{ base: '200px', md: '300px' }}
            value={groupPaymentForm.managerEmail}
            onChange={(e) => {
              const emailRegExp =
                /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
              setGroupPaymentFormError({
                ...groupPaymentFormError,
                managerEmail: !emailRegExp.test(e.currentTarget.value),
              });

              setGroupPaymentForm({
                ...groupPaymentForm,
                managerEmail: e.currentTarget.value,
              });
            }}
          />
        </Flex>
        {groupPaymentFormError.managerEmail && (
          <Text color={'red'} paddingLeft={'75px'}>
            {'올바른 이메일 형식을 입력해주세요'}
          </Text>
        )}
        <Flex alignItems={'center'} marginTop={'20px'}>
          <label
            style={{ whiteSpace: 'nowrap', marginRight: '15px', width: '60px' }}
          >
            전화번호
          </label>
          <Input
            type="tel"
            placeholder="- 없이 입력"
            w={{ base: '200px', md: '300px' }}
            value={groupPaymentForm.phone}
            onChange={(e) => {
              const phonePattern =
                /^(01[016789]\d{7,8})|(02\d{7,8})|(0[3-9]{1}[0-9]{1}\d{7,8})$/;
              setGroupPaymentFormError({
                ...groupPaymentFormError,
                phone: !phonePattern.test(e.currentTarget.value),
              });
              // 숫자 외의 문자 제거
              const onlyNums = e.target.value.replace(/[^0-9]/g, '');
              setGroupPaymentForm({ ...groupPaymentForm, phone: onlyNums });
            }}
            maxLength={11}
          />
        </Flex>
        {groupPaymentFormError.phone && (
          <Text color={'red'} paddingLeft={'75px'}>
            {'올바른 전화번호 형식을 입력해주세요'}
          </Text>
        )}
        <Flex alignItems={'center'} marginTop={'20px'}>
          <label
            style={{ whiteSpace: 'nowrap', marginRight: '15px', width: '60px' }}
          >
            상품옵션
          </label>
          <Select
            w={{ base: '200px', md: '230px' }}
            defaultValue={groupPaymentForm.membershipOption}
            onChange={(e) => {
              setGroupPaymentForm({
                ...groupPaymentForm,
                membershipOption: e.target.value,
              });
            }}
          >
            <option value="통합 1개월 이용권">통합 1개월 이용권</option>
            <option value="통합 3개월 이용권">통합 3개월 이용권</option>
            <option value="통합 6개월 이용권">통합 6개월 이용권</option>

            <option value="라이팅젤 1개월 이용권">라이팅젤 1개월 이용권</option>
            <option value="라이팅젤 3개월 이용권">라이팅젤 3개월 이용권</option>
            <option value="라이팅젤 6개월 이용권">라이팅젤 6개월 이용권</option>

            {/* <option value="드로잉젤">드로잉젤</option>
            <option value="채팅젤">채팅젤</option> */}
          </Select>
        </Flex>

        {emailInputs.map((emailData, index) => {
          return (
            <EmailInputsBox
              key={emailData.id}
              emailInputs={emailInputs}
              id={emailData.id}
              setEmailInputs={setEmailInputs}
            />
          );
        })}

        <Flex justifyContent={'space-between'} marginTop={'15px'}>
          <Button
            onClick={() => {
              setEmailInputs([
                ...emailInputs,
                {
                  id: shortid.generate(),
                  email: '',
                  isFormatValid: false,
                  isNotExist: undefined,
                },
              ]);
            }}
          >
            <AddIcon />
          </Button>
          <Button
            marginLeft={'10px'}
            colorScheme="blue"
            onClick={async (e) => {
              // 모든 계정 검증
              const getFinalPayEmails = emailInputs
                .filter((el) => el.email)
                .map((el) => {
                  return axios.get(
                    `${SERVER_URL}/user/profile/email?email=${el.email}`,
                  );
                });

              const finalResult = await Promise.allSettled(getFinalPayEmails);
              const newEmailInputs = emailInputs.slice();
              finalResult.forEach((el, index) => {
                if (el.status === 'rejected') {
                  newEmailInputs[index].isNotExist = true;

                  toast({
                    position: 'top-right',
                    title: el?.reason?.response?.data?.message,
                    description:
                      el?.reason?.response?.data?.config?.path?.split(
                        '?email=',
                      )[1],
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                } else {
                  newEmailInputs[index].isNotExist = false;
                }
              });
              console.log(newEmailInputs);
              setEmailInputs(newEmailInputs);
              // 검증된 계정만 결제 목록에 추가하기
              setGroupPaymentForm({
                ...groupPaymentForm,

                finalPayEmails: finalResult
                  .filter((el) => el.status === 'fulfilled')
                  .map((el) => ({
                    email: el.value.data.data.user.email,
                    user_uid: el.value.data.data.user.user_uid,
                  })),
              });
            }}
          >
            검증
          </Button>
        </Flex>
      </Box>

      <FinalGroupPayInfo />
    </Flex>
  );
};

export default GroupPay;
