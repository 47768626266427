import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {
  groupPaymentFormErrorState,
  groupPaymentFormState,
  ProfileState,
} from 'Config/recoil';
import React from 'react';
import { useRecoilState } from 'recoil';
import GroupPayQuotationButton from './GroupPayQuotationButton';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { checkedToken } from 'Hook/CheckedToken';
import { postMulitiplePay } from 'apis/user';
import { useNavigate } from 'react-router-dom';
import { planUid, prices, serviceToken } from 'Config/paymentInfomation';

const FinalGroupPayInfo = () => {
  const [groupPaymentForm, setGroupPaymentForm] = useRecoilState(
    groupPaymentFormState,
  );
  const [groupPaymentFormError, setGroupPaymentFormError] = useRecoilState(
    groupPaymentFormErrorState,
  );

  const [profile, setProfile] = useRecoilState(ProfileState);

  const toast = useToast();
  const token = localStorage.getItem('token');
  const { IMP } = window;
  const navigate = useNavigate();

  const totalOriginPrice =
    groupPaymentForm.finalPayEmails.length *
    prices[groupPaymentForm.membershipOption];

  const callGroupPayment = async () => {
    try {
      // 로그인 상태 검증
      const valid = await checkedToken(token);
      if (!valid) {
        toast({
          position: 'top-right',
          title: t('error.logged_out_title'),
          description: `${t('error.retry_login_description')}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // 유효성 검사
      const emailRegExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      const phonePattern =
        /^(01[016789]\d{7,8})|(02\d{7,8})|(0[3-9]{1}[0-9]{1}\d{7,8})$/;

      const validationResult = {
        institutionName: groupPaymentForm.institutionName.length <= 1,
        managerName: groupPaymentForm.managerName.length <= 1,
        phone: !phonePattern.test(groupPaymentForm.phone),
        departmentName: false,
        managerEmail: !emailRegExp.test(groupPaymentForm.managerEmail),
        membershipOption: false,
        finalPayEmails: groupPaymentForm.finalPayEmails.length === 0,
      };

      if (groupPaymentForm.finalPayEmails.length === 0) {
        toast({
          position: 'top-right',
          title: '검증된 이메일 없음',
          description: `이메일을 검증해주세요.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      } else if (groupPaymentForm.finalPayEmails.length < 5) {
        toast({
          position: 'top-right',
          title: '최소 계정 수 부족',
          description: `이메일은 5개 이상부터만 단체 결제 가능합니다.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // 유효성 체크 통과했는지 검증
      const errorArray = [...new Set(Object.values(validationResult))].filter(
        (el) => el,
      );

      if (errorArray.length !== 0) {
        setGroupPaymentFormError(validationResult);
        toast({
          position: 'top-right',
          title: '유효성 검증 실패',
          description: `올바른 양식을 입력해주세요.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // 결제 프로세스

      const now = new Date();
      const moidNum = dayjs(now).unix();
      const uid = profile.user.user_uid;

      const paymentUrl = {
        드로잉젤: 'drawingel',
        채팅젤: 'chatingel',
      };

      const merchant_uid = `${uid}_${
        paymentUrl[groupPaymentForm.membershipOption]
          ? serviceToken[groupPaymentForm.membershipOption] >= 100000
            ? 'c'
            : serviceToken[groupPaymentForm.membershipOption]
          : planUid[groupPaymentForm.membershipOption]
      }_${moidNum}`;

      localStorage.setItem(
        'redirect_payment',
        JSON.stringify({ membershipName: groupPaymentForm.membershipOption }),
      );

      IMP.init('imp33624147');
      IMP.request_pay(
        {
          pg: 'html5_inicis',
          pay_method: 'card',
          merchant_uid, // 상점에서 관리하는 주문 번호를 전달
          name: `${groupPaymentForm.membershipOption}-단체결제(${groupPaymentForm.finalPayEmails.length}명)`,
          amount: parseInt(
            prices[groupPaymentForm.membershipOption] *
              groupPaymentForm.finalPayEmails.length *
              0.8,
          ),
          buyer_email: groupPaymentForm.managerEmail,
          buyer_name: groupPaymentForm.managerName,
          buyer_tel: groupPaymentForm.phone,
          m_redirect_url: `${process.env.REACT_APP_FRONT_URL}/cardpay`,
        },
        async (rsp) => {
          if (!rsp.success) {
            toast({
              position: 'top-right',
              title: t('error.imp_problem'),
              description: `${rsp.error_msg}`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }

          if (rsp.success) {
            const plan_uid = paymentUrl[groupPaymentForm.membershipOption]
              ? serviceToken[groupPaymentForm.membershipOption]
              : planUid[groupPaymentForm.membershipOption];

            localStorage.setItem(
              'redirect_payment',
              JSON.stringify({
                service: 'inicis',
                plan_uid,
                membershipName: groupPaymentForm.membershipOption,
                imp_uid: rsp.imp_uid,
                merchant_uid,
                billingKey: '',
              }),
            );

            await postMulitiplePay(
              {
                service: 'inicis', //kakao/inicis/paypal
                imp_uid: rsp.imp_uid,
                merchant_uid,
                plan_uid: parseInt(plan_uid), // 플랜 uid
                planName: groupPaymentForm.membershipOption, // 플랜 이름
                user_uids: groupPaymentForm.finalPayEmails.map(
                  (el) => el.user_uid,
                ), // 원하는 유저 uid list
              },
              navigate,
            );
          }
        },
      );
    } catch (error) {
      const errorStatus = error.response.status;
      const errorResMessage = error.response.data.message;
      toast({
        position: 'top-right',
        title: 'Fail',
        description: `[${errorStatus}] ${errorResMessage}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      console.error(error);
    }
  };

  return (
    <Flex
      flexDirection={{ base: 'column', lg: 'row' }}
      w={'100%'}
      justifyContent={'center'}
      alignItems={{ base: 'center', lg: 'flex-start' }}
      marginTop={'50px'}
      height={'100%'}
    >
      <TableContainer margin="0 40px 40px 0">
        <Table variant="simple">
          <TableCaption>검증된 이메일만 최종 결제에 포함됩니다.</TableCaption>
          <Thead>
            <Tr>
              <Th>계정</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupPaymentForm.finalPayEmails.map((el) => {
              return (
                <Tr>
                  <Td>{el.email}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Box height={'100%'}>
        <Heading as="h4">결제</Heading>
        <Flex marginTop={'20px'} fontSize={'20px'}>
          <Text>상품&nbsp;:&nbsp;</Text>
          <Text fontWeight={'bold'}>{groupPaymentForm.membershipOption}</Text>
        </Flex>
        <Flex marginTop={'20px'} fontSize={'20px'}>
          <Text>기존 결제금액&nbsp;:&nbsp;</Text>
          <Text opacity={0.5} textDecoration={'line-through'}>
            {totalOriginPrice.toLocaleString('ko-KR')}
          </Text>
        </Flex>
        <Flex marginTop={'20px'} fontSize={'20px'}>
          <Text>할인율&nbsp;:&nbsp;</Text>
          <Text fontWeight={'bold'}>20%</Text>
        </Flex>
        <Flex marginTop={'30px'} fontSize={'30px'}>
          <Text>최종결제금액&nbsp;:&nbsp;</Text>
          <Text fontWeight={'bold'}>
            {(totalOriginPrice * 0.8).toLocaleString('ko-KR')}
          </Text>
        </Flex>
        <Flex w={'100%'} justifyContent={'space-between'} marginTop={'20px'}>
          <GroupPayQuotationButton />
          <Button size={'lg'} colorScheme="purple" onClick={callGroupPayment}>
            결제 하기
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default FinalGroupPayInfo;
