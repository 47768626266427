export const krwTousd = {
  25000: 19,
  60000: 45,
  90000: 68,
  20000: 15,
  50000: 38,
  80000: 60,
  10000: 8,
};

export const prices = {
  '통합 1개월 이용권': 25000,
  '통합 3개월 이용권': 60000,
  '통합 6개월 이용권': 90000,
  '라이팅젤 1개월 이용권': 20000,
  '라이팅젤 3개월 이용권': 50000,
  '라이팅젤 6개월 이용권': 80000,
  드로잉젤: 10000,
  채팅젤: 10000,
};

export const serviceToken = {
  '통합 1개월 이용권': 0,
  '통합 3개월 이용권': 0,
  '통합 6개월 이용권': 0,
  '라이팅젤 1개월 이용권': 0,
  '라이팅젤 3개월 이용권': 0,
  '라이팅젤 6개월 이용권': 0,
  드로잉젤: 80,
  채팅젤: 100000,
};

export const planUid = {
  '통합 1개월 이용권': 4,
  '통합 3개월 이용권': 5,
  '통합 6개월 이용권': 6,
  '라이팅젤 1개월 이용권': 1,
  '라이팅젤 3개월 이용권': 2,
  '라이팅젤 6개월 이용권': 3,
  드로잉젤: 80,
  채팅젤: 100000,
};
