import * as server from 'Config/server';
import axios from 'axios';

export async function checkedToken(token) {
  const config = {
    method: 'get',
    url: `${server.SERVER_URL}/user/profile`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await axios(config);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
