import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import styled from 'styled-components';
import { WarningIcon } from '@chakra-ui/icons';
import { t } from 'i18next';

const ExplainSignContent = styled(Box)`
  > h4 {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 15px 0;
    color: #000;
  }

  > p {
    color: #000;
    word-break: keep-all;
    font-size: 15px;
    line-height: 25px;

    &::before {
      content: ' ∙ ';
    }
  }
`;

const MemberPayInfo = () => {
  return (
    <Flex bg="#fff" p={'36px'} gap="30px" direction="column">
      <ExplainSignContent>
        <Heading as="h4" size="md">
          <WarningIcon w={6} h={6} mr="5px" color={'#6200ee'} />
          {t('membership.pay_info_heading1')}
        </Heading>
        <p>{t('membership.pay_info_description1')}</p>
        <p>{t('membership.pay_info_description2')}</p>
        <p>{t('membership.pay_info_description3')}</p>
      </ExplainSignContent>
      <ExplainSignContent>
        <Heading as="h4" size="md">
          <WarningIcon w={6} h={6} mr="5px" color={'#6200ee'} />
          {t('membership.pay_info_heading2')}
        </Heading>
        <p>{t('membership.pay_info_description4')}</p>
        <p>{t('membership.pay_info_description5')}</p>
        <p>{t('membership.pay_info_description6')}</p>
      </ExplainSignContent>
    </Flex>
  );
};
export default MemberPayInfo;
