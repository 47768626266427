import React, { useEffect, useRef, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { t } from 'i18next';
import styled from 'styled-components';
import TingelImageModel from 'Components/Home/TingelImageModel';
import LazyLoad from 'react-lazyload';

const TingelCharactor = styled.article`
  @keyframes upDown {
    from {
      transform: translateY(5%);
    }

    to {
      transform: translateY(-5%);
    }
  }

  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  animation: upDown 1s ease-in-out alternate infinite;

  & > picture > source,
  img {
    width: 130px;
    height: auto;
  }

  @media (max-width: 959px) {
    right: 25px;

    & > picture > source {
      width: 60px;
      /* height: 60px; */
      /* object-fit: cover; */
    }
  }
`;

const SpeechBubble = styled.div`
  position: relative;
  background: #f0f0f0;
  border-radius: 15px;
  padding: 20px;
  margin-right: 10px;
  margin-bottom: -10px;
  max-width: 275px;

  & > p {
    margin: 0;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    position: relative;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #f0f0f0 transparent transparent transparent;
  }

  & > span {
    font-size: 13px;
  }

  @media (max-width: 959px) {
    margin-bottom: 5px;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 90%;
      border-width: 10px;
      border-style: solid;
      border-color: #f0f0f0 transparent transparent transparent;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 1px;
  font-size: 25px;
  padding: 2.5px;
  cursor: pointer;
`;

// function lazyWithPrepload(importFunction) {
//   const Component = React.lazy(importFunction);
//   Component.preload = importFunction;
//   return Component;
// }

const FixedCharacterWithBubble = () => {
  const [tingelVisible, setTingelVisible] = useState(true);

  // const TingelImageModel = lazyWithPrepload(() =>
  //   import('Components/Home/TingelImageModel'),
  // );

  // useEffect(() => {
  //   TingelImageModel.preload();

  //   const img = new Image();
  //   img.src = '/images/tingel2-webp.webp';
  // }, []);

  return (
    <>
      {tingelVisible && (
        <TingelCharactor>
          <SpeechBubble>
            <CloseButton
              aria-label="character-close-button"
              onClick={() => {
                setTingelVisible((prev) => !prev);
              }}
            >
              <IoIosClose />
            </CloseButton>
            <p>
              {t('tingel.title_1')}

              <br />
              {t('tingel.title_2')}
            </p>
            <span>
              <a
                href="https://forms.gle/Ph7p8bMJmP1jKWoP8"
                target="_blank"
                rel="noopner noreferrer"
              >
                {t('tingel.link')}&nbsp;{'>'}
              </a>
            </span>
          </SpeechBubble>
          <LazyLoad height={200} offset={100}>
            <TingelImageModel />
          </LazyLoad>
        </TingelCharactor>
      )}
    </>
  );
};

export default FixedCharacterWithBubble;
