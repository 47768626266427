import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Input, Text, useToast } from '@chakra-ui/react';

import React from 'react';
import styled from 'styled-components';

const EmailInputsBox = ({ emailInputs, id, setEmailInputs }) => {
  const toast = useToast();

  const thisInput = emailInputs.find((el) => el.id === id);

  const onChangeEmail = (e) => {
    setEmailInputs((prev) => {
      const emailRegExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      const findedPrevData = prev.find((el) => el.id === id);
      findedPrevData.email = e.target.value;
      findedPrevData.isFormatValid = !emailRegExp.test(e.target.value);
      return [...prev];
    });
  };

  const deleteEmailInputs = () => {
    if (emailInputs.length <= 5) {
      toast({
        position: 'top-right',
        title: '최소 입력 기준',
        description: '단체 결제는 최소 5명 이상 등록해야 합니다.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setEmailInputs((prev) => {
      const newEmailInputs = prev.slice();
      const selectedIndex = newEmailInputs.indexOf(
        prev.find((el) => el.id === id),
      );
      newEmailInputs.splice(selectedIndex, 1);
      return newEmailInputs;
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <label
          style={{
            whiteSpace: 'nowrap',
            marginRight: '15px',
            width: '60px',
          }}
        >
          등록 계정
        </label>
        <Input
          w={{ base: '200px', md: '300px' }}
          onChange={onChangeEmail}
          value={thisInput.email}
        />

        <Button marginLeft={'10px'} onClick={deleteEmailInputs}>
          <DeleteIcon />
        </Button>
      </div>
      {thisInput.isFormatValid && (
        <Text color={'red'} paddingLeft={'75px'}>
          이메일 형식이 올바르지 않습니다
        </Text>
      )}
      {thisInput.isNotExist && (
        <Text color={'red'} paddingLeft={'75px'}>
          존재하지 않는 이메일 입니다.
        </Text>
      )}
    </div>
  );
};

export default React.memo(EmailInputsBox);
