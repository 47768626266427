import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { isSubscriptionRestrictModalOpenState } from 'Config/recoil';
import React from 'react';
import { useRecoilState } from 'recoil';

const SubscriptionRestrictModal = () => {
  const [isSubscriptionRestrictModalOpen, setIsSubscirptionRestrictModalOpen] =
    useRecoilState(isSubscriptionRestrictModalOpenState);
  return (
    <Modal
      onClose={() => {
        setIsSubscirptionRestrictModalOpen(false);
      }}
      size={'lg'}
      isOpen={isSubscriptionRestrictModalOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight={'bold'}>정기결제 중복선택</Text>
        </ModalHeader>
        <ModalBody
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text textAlign={'center'}>이미 정기 결제중인 상품입니다.</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsSubscirptionRestrictModalOpen(false);
            }}
          >
            확인
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SubscriptionRestrictModal;
