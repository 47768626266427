import React, { useState } from 'react';
import {
  Flex,
  Heading,
  Text,
  Box,
  Button,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { CardUI, CardHeader, CardBody } from 'Components/Common/Card';
import { t } from 'i18next';
import { useRecoilState } from 'recoil';
import { getLanguage } from 'language/i18n';
import { selectedProductUIDState } from 'Config/recoil';
import { checkedToken } from 'Hook/CheckedToken';
import useLoadPaypalUI from 'Hook/useLoadPaypalUI';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import FreeMembershipCard from './FreeMembershipCard';
import UseCreatePayment from 'Hook/UseCreatePayment';

const ChattingelMembership = ({ setPaymentInfomation }) => {
  const [selectedProductUID, setSelectedProductUID] = useRecoilState(
    selectedProductUIDState,
  );
  const toast = useToast();
  const navigate = useNavigate();
  const createPayment = UseCreatePayment();

  const loadPaypalUI = useLoadPaypalUI();

  const token = localStorage.getItem('token');

  const HandleSelect = async (e) => {
    try {
      setSelectedProductUID('chatting-1');

      setPaymentInfomation({
        paymentMap: 'CHAT_PAYMENT',
        planUid: '100000',
        price: 10000,
        membershipName: '채팅젤',
        serviceToken: 100000,
      });

      const valid = await checkedToken(token);

      // 페이팔 렌더링
      const user = JSON.parse(localStorage.getItem('User'));

      loadPaypalUI({
        uid: '100000',
        serviceToken: 100000,
        moidNum: dayjs(new Date()).unix(),
        membershipName: '채팅젤',
        price: 8,
        email: user.email,
        userName: user.userName,
        planUid: '100000',
        callback: createPayment,
        valid,
      });
    } catch (error) {
      console.error(error);
      toast({
        position: 'top-right',
        title: t('error.payment_fail_login_expired_title'),
        description: t('error.payment_fail_login_expired_description'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      navigate('/sign/login');
    }
  };

  return (
    <SimpleGrid
      w={'100%'}
      justify="center"
      maxW="800px"
      m="0 auto"
      templateColumns={{
        md: 'repeat(2, 1fr)',
      }}
      spacing="40px"
    >
      <FreeMembershipCard />
      <CardUI selected={selectedProductUID === 'chatting-1'}>
        <CardHeader>
          <Heading
            as="h3"
            size="sm"
            pb="15px"
            borderBottom={'1px solid #ededed'}
          >
            {t('membership.category_chattingel')}
          </Heading>
          <Text fontSize={'2xl'} fontWeight="600" pt="15px">
            {getLanguage() === 'ko' ? '10,000' : '8'}
            {t('membership.currency')}
          </Text>
        </CardHeader>
        <CardBody>
          <Box lineHeight={'30px'}>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              {t('membership.chattingel_benefit1')}{' '}
            </Flex>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              {t('membership.chattingel_benefit2')}
            </Flex>
          </Box>
          <Button
            w="100%"
            colorScheme="purple"
            name="1 10000"
            onClick={HandleSelect}
          >
            {t('membership.select_button')}
          </Button>
        </CardBody>
      </CardUI>
    </SimpleGrid>
  );
};

export default ChattingelMembership;
