import React from 'react';

const TingelImageModel = () => {
  return (
    <picture>
      <source
        srcSet="/images/tingel2-webp.webp"
        media="all"
        type="image/webp"
      />
      <img src="/images/tingel2.png" alt="tingel2-original" />
    </picture>
  );
};

export default TingelImageModel;
